import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Header from "./components/layout/Header";
import Menu from "./components/layout/Menu";
import SideMenu from "./components/layout/SideMenu";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/login/Login";
import Sales from "./components/subMenu/Sales";
import Expense from "./components/subMenu/Expense";
import BoxCash from "./components/subMenu/BoxCash";
import PayLater from "./components/subMenu/PayLater";
import Customer from "./components/menuList/Customer";
import AddProducts from "./components/menuList/products/AddProducts";
import ListProducts from "./components/menuList/products/ListProducts";
import ManageStokes from "./components/menuList/products/ManageStokes";
import DynamicTitleAndIcon from "./components/dashboard/DynamicTitleAndIcon ";
import { getAccessStoreToken,  getRefreshStoreToken,  getRefreshToken,  removeAccessStoreToken,  removeAccessToken, removeRefreshStoreToken, removeRefreshToken } from "./components/utils/CookieUtiles";
import { createToken, viewstore } from "./components/utils/CreateApi";
import AddProfile from "./components/dashboard/addProfile/AddProfile";


function App() {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const [isProfile, setProfile] = useState(false);
let authStoreToken=getAccessStoreToken();
  const handleLogin = () => {
    setLoggedIn(true);
    navigate("/dashboard");
  };
  const toggleSideMenu = () => {
    setSideMenuOpen(!isSideMenuOpen);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    navigate("/login");
    removeAccessToken();
    removeRefreshStoreToken();
    removeAccessStoreToken();
    removeRefreshToken();
  
  };

  useEffect(() => {
    const token = getAccessStoreToken();
    const refreshToken = getRefreshStoreToken();
  console.log("refresh store",getRefreshStoreToken())
  console.log("refresh ",getRefreshToken())
  console.log("access store",getAccessStoreToken())
    if (!refreshToken) {
      handleLogout(); 
      navigate("/login");
      return;
    }
  if(token &&refreshToken ){
    setProfile(true);
    navigate("/dashborad")
  }
    if (token) {
      setProfile(true);
      setLoggedIn(true);
      
    } else {
     
      createToken(navigate, setLoggedIn)
        .then(newToken => {
          if (newToken) {
            setProfile(true);
          
            authStoreToken = newToken; 
          } else {
            handleLogout(); 
          }
        })
        .catch(() => handleLogout()); 
    }
  }, []);
  
  // useEffect(() => {
  //   console.log("access",getAccessStoreToken())
  //   console.log("refresh",getRefreshStoreToken())
  //   const token = getAccessStoreToken();
  //   const refreshToken = getRefreshStoreToken();
    
  //   if (!refreshToken) {
  //     handleLogout();
  //     return;
  //   }

  //   if (token) {
      
  //     setProfile(true);
  //     authStoreToken=token;
  //   } else {
  //     createToken(navigate, setLoggedIn);
  //     viewstore(navigate, setLoggedIn)
  //     authStoreToken=token;
  //   }
  // }, []);

  return (
    <div>
      <DynamicTitleAndIcon />
      {!loggedIn ? (
        
        <Login setLoggedIn={handleLogin}  setProfile={setProfile}/>
      ) : isProfile ? (
        <>
          <Header toggleSideMenu={toggleSideMenu} setLoggedIn={handleLogout}  />
          <Menu />
          {isSideMenuOpen && <SideMenu toggleSideMenu={toggleSideMenu} />}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard authToken={authStoreToken} />} />
            <Route path="/sales" element={<Sales authToken={authStoreToken} />} />
            <Route path="/expenses" element={<Expense authToken={authStoreToken} />} />
            <Route path="/boxcash" element={<BoxCash authToken={authStoreToken} />} />
            <Route path="/paylater" element={<PayLater authToken={authStoreToken} />} />
            <Route path="/customers" element={<Customer authToken={authStoreToken} />} />
            <Route path="/products" element={<AddProducts authToken={authStoreToken} />} />
            <Route path="/listproducts" element={<ListProducts authToken={authStoreToken} />} />
            <Route path="/stocks" element={<ManageStokes authToken={authStoreToken} />} />
            
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/addprofile" element={<AddProfile setProfile={setProfile} setLoggedIn={setLoggedIn} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
