import React from 'react';

function CustomButton({ text, icon, className, onClick }) {
  return (
    <button className={`btn shadow-none ${className}`} onClick={onClick}>
      <i className={icon}></i> {text}
    </button>
  );
}

export default CustomButton;
