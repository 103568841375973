import React, { useState } from "react";
import CustomButtonAdd from "../reusable/CusstomButtonAdd";
import CancelButton from "../reusable/CancelButton";
import PayLaterTable from "../reusable/tables/PayLatertable";
import AddButton from "../reusable/AddButton";
const customerData = [
  {
    id: 1,
    date: "03 Nov 2023",
    time: "06:15 pm",
    name: "Air Jordan",
    phone: "8762323946",
    dueAmount: "₹850.00",
  },
  {
    id: 2,
    date: "03 Nov 2023",
    time: "06:15 pm",
    name: "Sakthi Industries Pvt Ltd",
    phone: "8762323946",
    dueAmount: "- ₹1250.05",
  },
  {
    id: 3,
    date: "03 Nov 2023",
    time: "06:15 pm",
    name: "VVD Hardwares and Softwares",
    phone: "8762323946",
    dueAmount: "- ₹3430.05",
  },
];

function PayLater() {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleAction = (id) => {
    console.log(`Viewing details for customer with id: ${id}`);
  };
  const handleAdd = () => {
    console.log('Add button clicked');
  };
  const stopEventPropagation = (e) => {
    e.stopPropagation();  
  };

  return (
    <React.Fragment>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-flex align-items-center">
            <div className="breadcrumb-title pe-3 py-2">Pay Later</div>
            <div className="ms-auto">
              <CustomButtonAdd
                text="Add Pay Later Customer"
                icon="bx-plus"
                onClick={handleOpenModal}
                className="btn btn-primary"
              />
            
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card radius-10 mb-0 mt-3">
                <div className="card-widget-separator-wrapper">
                  <div className="card-body px-0">
                    <div className="row px-3">
                      <div className="col-lg-5 me-auto">
                        <div className="ms-auto position-relative">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search customer by name, phone etc..."
                            />
                            <button className="input-group-text">
                              <i className="bx bx-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isModalOpen && (<>
                        <div className="modal-backdrop show" onClick={handleCloseModal}  ></div>
                      <div
                        class="modal show"
                        id="PaylaterCustomerModal"
                        tabindex="-1"
                        aria-modal="true"
                        role="dialog"
                        style={{ display: "block" }}
                        onClick={handleCloseModal}
                      >
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" onClick={stopEventPropagation}>
                          <div class="modal-content">
                            <div class="modal-header">
                              <h6 class="modal-title">
                                Add Pay Later Customer
                              </h6>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleCloseModal}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body bg-light">
                              <form>
                                <div class="card mb-0">
                                  <div class="card-body">
                                    <div class="row g-3">
                                      <div class="col-lg-12">
                                        <label
                                          for="FisrtName"
                                          class="form-label"
                                        >
                                          Name{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          autofocus=""
                                          fdprocessedid="sy8z4"
                                        />
                                      </div>
                                      <div class="col-lg-12">
                                        <label
                                          for="LastName"
                                          class="form-label"
                                        >
                                          Phone{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <div class="input-group">
                                          <span
                                            class="input-group-text"
                                            id="basic-addon41"
                                          >
                                            +91
                                          </span>
                                          <input
                                            type="text"
                                            class="form-control"
                                            fdprocessedid="e51yom"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="modal-footer d-block">
                              <CancelButton
                                buttonText="Cancel"
                                buttonIcon="bx bx-x"
                                onClick={handleCloseModal}
                                additionalClasses="btn-outline-secondary text-left"
                              />
                              <AddButton label="Add"
        iconClass="bx bx-check"
        buttonClass="btn btn-primary text-right"
        onClick={handleAdd}/>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                    )}

                    <PayLaterTable
                      data={customerData}
                      handleAction={handleAction}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PayLater;
