// BoxCashTable.js
import React from 'react';
import DeleteButton from '../DeleteIcon';

const BoxCashTable = ({ data, handleDelete }) => {
  return (
    <div className="card radius-10 mb-0 mt-3">
      <div className="card-widget-separator-wrapper">
        <div className="card-body px-0">
          <div className="table-responsive">
            <table className="table align-middle mb-0">
              <thead className="table-light text-center">
                <tr>
                  <th>S.No</th>
                  <th>Mode</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td className="text-center">{item.id}</td>
                    <td className="text-center">
                      <span className={`badge rounded-pill bg-label-${item.mode === 'Cash In' ? 'green' : 'pink'}`}>{item.mode}</span>
                    </td>
                    <td className="text-center">{item.date}</td>
                    <td className="text-center">{item.time}</td>
                    <td className="text-center">
                      <div className="d-flex flex-column">
                        <span className="text-nowrap text-heading fw-medium">
                          <span className={`badge rounded-pill bg-label-${item.badgeColor}`}>{item.badge}</span> - Air Jordan
                        </span>
                        <small className="text-truncate d-none d-sm-block">{item.description}</small>
                      </div>
                    </td>
                    <td className="text-center">
                      <span className={`text-heading fw-medium text-truncate ${item.mode === 'Cash Out' ? 'text-pink' : ''}`}>{item.amount}</span>
                    </td>
                    <td className="text-center">
                    <div className="order-actions">
                    <DeleteButton onClick={() => handleDelete(item.id)} />
                  </div>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxCashTable;
