// ReusableButton.js
import React from 'react';

const ViewButton = ({ onClick, iconClass, labelClass }) => {
  return (
    <div className="order-actions">
    <button className={labelClass} onClick={onClick}>
      <i className={iconClass}></i>
    </button></div>
  );
};

export default ViewButton;
