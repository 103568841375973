import React, { useEffect, useRef, useState } from 'react';
import CancelButton from '../../reusable/CancelButton';
import AddButton from '../../reusable/AddButton';
import ButtonWithProgress from '../../reusable/ButtonWithProgress';

function EditProductDialog({ productData, onClose, updateProduct, buttonloading }) {
    const [formData, setFormData] = useState({
      productid:productData._id,
        code: productData.code || '',
        hsnsac: productData.hsnsac || '',
        title: productData.title || '',
        mrp: productData.mrp || '',
        saleprice: productData.saleprice || '',
        taxpercentage: productData.taxpercentage || '0',
        discount: productData.discount || '',
        baseprice: productData.baseprice || '',
        taxamount: productData.taxamount || '0',
    });
    const productTitleRef = useRef(null);
    const productsalepriceRef = useRef(null);
    const productCodeRef = useRef(null);
    const productHsnsacRef = useRef(null);
    const productMrpRef = useRef(null);
    const producttaxpercentageeRef = useRef(null);
    const AddButtonRef = useRef(null);
    const [selectedInput, setSelectedInput] = useState("saleprice");
    const handleRadioChange = (inputType) => {
      setSelectedInput(inputType);
    };
    const productbasepriceRef = useRef(null);
    const validateFields = () => {
      let valid = true;
      const newErrors = {};
  
      if (!formData.title) {
        newErrors.title = "This field must not be empty. Please enter a value.";
        valid = false;
       
      }   else if (!formData.saleprice || isNaN(formData.saleprice)) {
        newErrors.saleprice =
          "This field must not be empty. Please enter a value.";
        valid = false;
       
      }else if (
        parseFloat(formData.saleprice) > parseFloat(formData.mrp)
      ) {
        newErrors.saleprice = "Sale price should be less than or equal to MRP.";
        valid = false;
        
      }
  
      setErrors(newErrors);
      return valid;
    };
    const [errors, setErrors] = useState({});

    const stopEventPropagation = (e) => {
        e.stopPropagation();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let trimmedValue;
        if (
          name === "code" ||
          name === "mrp" ||
          name === "saleprice" ||
          name === "discount" ||
          name === "baseprice" ||
          name === "taxamount"
        ) {
          trimmedValue = value.replace(/\s/g, "");
        } else {
          trimmedValue = value.trimStart().replace(/\s+/g, " ");
        }
        setFormData((prevData) => {
          const updatedData = { ...formData, [name]: trimmedValue };
          return updatedData;
        });
       
    };

    const handleUpdate = () => {
      if (!validateFields()) {
        return;
      }
        updateProduct(formData);
    };
    
    const handleMrpChange = (e) => {
      const newMrp = e.target.value;
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          mrp: newMrp,
        };
  
        updatedData.discount = calculateDiscount(newMrp, prevData.saleprice);
        return updatedData;
      });
    };
  
    const handleSellingPriceChange = (e) => {
      let newSalePrice = e.target.value;
      
      
      newSalePrice = newSalePrice.replace(/[a-zA-Z]/g, "");
      const taxRate = parseFloat(formData.taxpercentage) || 0;
    
      
      const newBasePrice = newSalePrice
        ? (Math.floor(parseFloat(newSalePrice) / (1 + taxRate / 100) * 100) / 100).toFixed(2)
        : "0.00";
    
      setFormData((prevData) => {
       
        const newTaxAmount = newSalePrice && newBasePrice
          ? (parseFloat(newSalePrice) - parseFloat(newBasePrice)).toFixed(2)
          : "0.00";
    
        return {
          ...prevData,
          saleprice: newSalePrice,
          baseprice: newBasePrice,
          taxamount: newTaxAmount,
        };
      });
    };
    const handleBlur = () => {
      if (!formData.saleprice || formData.saleprice === "") {
      
        setFormData((prevData) => {
          const newSalePrice = formData.mrp;
          const taxRate = parseFloat(prevData.taxpercentage) || 0;
    
          const basePrice = newSalePrice
            ? (Math.floor((parseFloat(newSalePrice) / (1 + taxRate / 100)) * 100) / 100).toFixed(2)
            : "0.00";
            const taxAmount = (parseFloat(newSalePrice) - parseFloat(basePrice)).toFixed(2);
          return {
            ...prevData,
            saleprice: newSalePrice,
            baseprice: basePrice,
            taxamount: taxAmount,
          };
        });
        console.log("Sale Price is empty, setting it to MRP:", formData.mrp);
      } else if (parseFloat(formData.mrp) < parseFloat(formData.saleprice)) {
        
        setFormData((prevData) => {
          const newSalePrice = formData.mrp;
          const taxRate = parseFloat(prevData.taxpercentage) || 0;
    
          const basePrice = newSalePrice
            ? (Math.floor((parseFloat(newSalePrice) / (1 + taxRate / 100)) * 100) / 100).toFixed(2)
            : "0.00";
            const taxAmount = (parseFloat(formData.saleprice) - parseFloat(basePrice)).toFixed(2);
          return {
            ...prevData,
            saleprice: newSalePrice,
            baseprice: basePrice,
            taxamount: taxAmount,
          };
        });
        console.log("MRP is less than Sale Price, setting Sale Price to MRP:", formData.mrp);
      } else if (!formData.mrp || formData.mrp === "") {
        
        setFormData((prevData) => {
          const newMrp = formData.saleprice;
          const taxRate = parseFloat(prevData.taxpercentage) || 0;
    
          const basePrice = newMrp
            ? (Math.floor((parseFloat(newMrp) / (1 + taxRate / 100)) * 100) / 100).toFixed(2)
            : "0.00";
            const taxAmount = (parseFloat(formData.saleprice) - parseFloat(basePrice)).toFixed(2);
          return {
            ...prevData,
            mrp: newMrp,
            baseprice: basePrice,
            taxamount: taxAmount,
          };
        });
        console.log("MRP is empty, setting MRP to Sale Price:", formData.saleprice);
      }
    };
    const handleTaxPercentageChange = (e) => {
      const newTaxPercentage = parseFloat(e.target.value);
    
      setFormData((prevData) => {
        let updatedSalePrice, updatedBasePrice, updatedTaxAmount;
    
        if (selectedInput === "saleprice") {
          updatedSalePrice = parseFloat(prevData.saleprice);
          updatedBasePrice = (
            Math.floor((updatedSalePrice / (1 + newTaxPercentage / 100)) * 100) / 100
          ).toFixed(2);  
          updatedTaxAmount = (
            updatedSalePrice - parseFloat(updatedBasePrice)
          ).toFixed(2);
        }
     
        else if (selectedInput === "baseprice") {
          updatedBasePrice = parseFloat(prevData.baseprice);
          updatedSalePrice = (
            parseFloat(updatedBasePrice * (1 + newTaxPercentage / 100) * 100) / 100
          ).toFixed(2);  
          updatedTaxAmount = (
            parseFloat(updatedSalePrice) - updatedBasePrice
          ).toFixed(2);
        }
        
        let updatedMrp = prevData.mrp;
        if (parseFloat(updatedSalePrice) > parseFloat(prevData.mrp)) {
          updatedMrp = updatedSalePrice;
        }
    
        return {
          ...prevData,
          taxpercentage: newTaxPercentage,
          saleprice: updatedSalePrice,
          baseprice: updatedBasePrice,
          taxamount: updatedTaxAmount,
          mrp: updatedMrp,
        };
      });
    };
    
    const calculateDiscount = (mrp, saleprice) => {
      const mrpValue = parseFloat(mrp) || 0;
      const salepriceValue = parseFloat(saleprice) || 0;
  
      const discount = mrpValue - salepriceValue;
  
      if (discount < 0) {
        return "0";
      } else if (discount === 0) {
        return "0.00";
      } else {
        return discount.toFixed(2);
      }
    };
    useEffect(() => {
      const { mrp, saleprice,  } = formData;
    
     
      const newDiscount = calculateDiscount(mrp, saleprice);
    
      setFormData((prevData) => ({
        ...prevData,
        discount: newDiscount,
            }));
    },[formData.mrp, formData.saleprice, formData.taxpercentage, formData.baseprice]);
    
  
    const handleBasePriceChange = (e) => {
      const { value } = e.target;
   
      let newBasePrice = value.replace(/[^\d.]/g, "");
    
      if ((newBasePrice.match(/\./g) || []).length > 1) {
        newBasePrice = newBasePrice.replace(/\.+$/, ""); 
      }
    
      
      if (!newBasePrice) {
        newBasePrice = "0.00";
      }
    
      setFormData((prevData) => {
        const taxRate = parseFloat(formData.taxpercentage) || 0;
    
       
        const calculatedSalePrice = Math.ceil(parseFloat(newBasePrice) * (1 + taxRate / 100)).toFixed(2);
        
       
        const calculatedTaxAmount = (calculatedSalePrice - parseFloat(newBasePrice)).toFixed(2);
    
        const updatedData = {
          ...prevData,
          baseprice: newBasePrice,
          saleprice: calculatedSalePrice,
          taxamount: calculatedTaxAmount,
        };
    
        
        if (parseFloat(calculatedSalePrice) > parseFloat(prevData.mrp)) {
          updatedData.mrp = calculatedSalePrice;
        }
    
        return updatedData;
      });
    };
    const handleKeyDown = (e, fieldRef, fieldName) => {
      setErrors("");
      if (e.key === "Enter") {
        e.preventDefault();
  
        const fields = [
          { name: "code", ref: productCodeRef, required: true },
          { name: "hsnsac", ref: productHsnsacRef, required: false },
          { name: "title", ref: productTitleRef, required: true },
          { name: "mrp", ref: productMrpRef, required: false },
          { name: "saleprice", ref: productsalepriceRef, required: true },
          {
            name: "taxpercentage",
            ref: producttaxpercentageeRef,
            required: true,
          },
          { name: "baseprice", ref: productbasepriceRef, required: false },
        ];
  
        const currentIndex = fields.findIndex(
          (field) => field.name === fieldName
        );
  
        if (fieldName === "baseprice") {
          handleUpdate();
          return;
        }
      
        if (
          fields[currentIndex].required &&
          formData[fieldName]?.trim() === ""
        ) {
          fieldRef?.current?.focus();
          return;
        }
  
        const nextField = fields[currentIndex + 1];
        if (nextField) {
          nextField.ref?.current?.focus();
        }
      }
    };
    useEffect(() => {
      productCodeRef.current && productCodeRef.current.focus();
   
    }, []);
    return (
        <div>
            <div className="modal-backdrop show" onClick={onClose}></div>
            <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
                <div className="modal-dialog modal-lg modal-dialog-scrollable" 
                onClick={stopEventPropagation}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="ExpenseModalLabel">
                                Update Product
                            </h6>
                            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-light">
                            <form>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-lg-6">
                                                <label htmlFor="code" className="form-label">
                                                    Barcode / SKU / Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="code"
                                                    autoComplete="off"
                                                    value={formData.code}
                                                    onChange={handleChange}
                                                    onKeyDown={(e) =>
                                                      handleKeyDown(e, productHsnsacRef, "hsnsac")
                                                    }
                                                    ref={productHsnsacRef}
                                              />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="hsnsac" className="form-label">
                                                    HSN/SAC
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="hsnsac"
                                                    ref={productTitleRef}
                                                    onKeyDown={(e) =>
                                                      handleKeyDown(e, productTitleRef, "title")
                                                    }
                                                    autoComplete="off"
                                                    value={formData.hsnsac}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label htmlFor="title" className="form-label">
                                                    Title <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    autoComplete="off"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                />
                                                {errors.title && <div className="error-message invalid-feedback">{errors.title}</div>}
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="mrp" className="form-label">
                                                    MRP
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="mrp"
                                                    autoComplete="off"
                                                    ref={productMrpRef}
                        onKeyDown={(e) =>
                          handleKeyDown(e, productMrpRef, "mrp")
                        }
                                                    value={formData.mrp}
                                                    onBlur={handleBlur}
                                                    onChange={handleMrpChange}
                                                />
                                                {errors.mrp && <div className="error-message invalid-feedback">{errors.mrp}</div>}
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="saleprice" className="form-label">
                                                    Sale Price <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-container position-relative">
                        <input
                          type="text"
                          name="saleprice"
                          autoComplete="off"
                          value={formData.saleprice}
                          onClick={() => handleRadioChange("saleprice")}
                          onChange={handleSellingPriceChange}
                          ref={productsalepriceRef}
                          onKeyDown={(e) => handleKeyDown(e, productsalepriceRef, 'saleprice')} 
                          className="form-control"
                          style={{ paddingLeft: "30px" }}
                          onBlur={handleBlur}
                        />
                        <input
                          type="radio"
                          name="selectInput"
                          checked={selectedInput === "saleprice"}
                          onChange={() => handleRadioChange("saleprice")}
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        />
                      </div>
                                               
                                                {errors.saleprice && (
                                                    <div className="error-message invalid-feedback">{errors.saleprice}</div>
                                                )}
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="taxpercentage" className="form-label">
                                                    Tax %
                                                </label>
                                                <select
                                                    name="taxpercentage"
                                                    className="form-control"
                                                    value={formData.taxpercentage}
                                                    ref={producttaxpercentageeRef}
                                                    onChange={handleTaxPercentageChange}
                                                    onKeyDown={(e) =>
                                                      handleKeyDown(
                                                        e,
                                                        producttaxpercentageeRef,
                                                        "taxpercentage"
                                                      )
                                                    }
                                                >
                                                    <option value="0">0</option>
                                                    <option value="5">5</option>
                                                    <option value="12">12</option>
                                                    <option value="18">18</option>
                                                    <option value="24">24</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="discount" className="form-label">
                                                    Discount
                                                </label>
                                                <input
                                                  readonly=""
                                                    type="text"
                                                    className="form-control"
                                                    name="discount"
                                                    autoComplete="off"
                                                    value={formData.discount}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="baseprice" className="form-label">
                                                    Base Price
                                                </label>
                                          
                                                <div className="input-container position-relative">
                        <input
                          type="text"
                          name="baseprice"
                          autoComplete="off" 
                          ref={productbasepriceRef}
                          value={formData.baseprice}
                          onClick={() => handleRadioChange("baseprice")}
                          onChange={handleBasePriceChange}
                          onKeyDown={(e) => handleKeyDown(e, productbasepriceRef, 'baseprice')} 
                          onBlur={handleBlur}
                          
                          className="form-control"
                          style={{ paddingLeft: "30px" }}
                        />
                        <input
                          type="radio"
                          name="selectInput"
                          checked={selectedInput === "baseprice"}
                          onChange={() => handleRadioChange("baseprice")}
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        />  
                      </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="taxamount" className="form-label">
                                                    Tax Amount
                                                </label>
                                                <input
                                                  readonly=""
                                                    type="text"
                                                    className="form-control"
                                                    name="taxamount"
                                                    autoComplete="off"
                                                    value={formData.taxamount === "" ? "0.00" : formData.taxamount}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer d-block">
                            <CancelButton
                                buttonText="Cancel"
                                buttonIcon="bx bx-x"
                                onClick={onClose}
                                additionalClasses="btn-outline-secondary text-left"
                            />
                            {buttonloading ? (
                                <ButtonWithProgress
                                    label="  Updating..."
                                    iconClass="bx bx-check"
                                    buttonClass="btn btn-primary text-right"
                                  
                                />
                            ) : (
                                <AddButton
                                    label="Update"
                                    iconClass="bx bx-check"
                                    buttonClass="btn btn-primary text-right"
                                    onClick={handleUpdate}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProductDialog;
