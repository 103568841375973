

// import React from 'react';

// function DatePickerButton({ inputValue, className, onInputChange, inputPlaceholder }) {
//   console.log("input",inputValue)
//   return (
//     <div className="input-group">
//       <input
//         type="date"
//         className={`form-control ${className}`}
//         value={inputValue}
//         onChange={onInputChange}
//         placeholder={inputPlaceholder}
//       />
//     </div>
//   );
// }

// export default DatePickerButton;
import React, { useState, useEffect } from 'react';

function DatePickerButton({ inputValue, className, onInputChange, inputPlaceholder }) {

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [displayDate, setDisplayDate] = useState(getCurrentDate());

  useEffect(() => {
    if (!inputValue) {
      const currentDate = getCurrentDate();
      setDisplayDate(currentDate);
      onInputChange({ target: { value: convertToISO(currentDate) } });
    }
  }, [inputValue, onInputChange]);


  const convertToISO = (date) => {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };

 
  const handleDateChange = (e) => {
    const isoDate = e.target.value;
    const [year, month, day] = isoDate.split('-');
    const formattedDate = `${day}-${month}-${year}`;

    setDisplayDate(formattedDate);
    onInputChange({ target: { value: isoDate } });
  };

  return (
    <div className="input-group">
      <input
        type="date"
        className={`form-control ${className}`}
        value={convertToISO(displayDate)}
        onChange={handleDateChange}
        placeholder={inputPlaceholder}
      />
    </div>
  );
}

export default DatePickerButton;
