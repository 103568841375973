
import React, { useState } from 'react';
import DeleteButton from '../DeleteIcon'; 

import { Skeleton } from '@mui/material';
import EditButton from '../EditIcon';

import DeleteDialog from '../buttons/Deletedialog';

const ExprnseTableData = ({data,isloading,onDelete}) => {


  const [selectedExpenseData, setSelectedExpenseData] = useState(null);
  
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);


 
  
  const getColor = (status) => {
    if (!status) return "bg-label-default"; // Default color if status is undefined or null
    switch (status.toLowerCase()) {
      case "cash":
        return "bg-label-green";
      case "cashin":
        return "bg-label-info";
      case "cashout":
        return "bg-label-info";
      case "other":
        return "bg-label-success";
      default:
        return "bg-label-default";
    }
  };
  

 

  const confirmDelete = () => {
    onDelete(selectedExpenseData._id);
    setIsDeleteDialogOpen(false);
  };
  const openDeleteDialog = (expense) => {
    setSelectedExpenseData(expense);
    setIsDeleteDialogOpen(true);
  };







  return (
    <div className="table-responsive">
      <table className="table align-middle mb-0">
        <thead className="table-light text-center">
          <tr>
            <th className="text-center">S.No</th>
            <th className="text-center">Date</th>
            <th className="text-center">Time</th>
            <th className="text-center">Title</th>
            <th className="text-center">Description</th>
            <th className="text-center">Pay Mode</th>
            <th className="text-center">Amount</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
            {isloading ? (
              [...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Skeleton variant="text" width={200} />
                  </td>

                  <td className="text-center">
                    <Skeleton variant="center" width={300} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                
                </tr>
              ))
            ) : Array.isArray(data) && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item.id || index}>
                  <td className="text-center">{index + 1}</td>
                 
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium">
                    {item.expensedate ? new Date(item.expensedate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : ""}
                 
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium">
                      {item.expensetime}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium" style={{ textTransform: 'capitalize' }}>
                      {item.title}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium" style={{ textTransform: 'capitalize' }}>
                      {item.description}
                    </span>
                  </td>
                  <td className="text-center">
                    {/* <span className="text-nowrap text-heading fw-medium"> */}
                    <span className={`badge rounded-pill ${getColor(item.paymode)}`}>
      {item.paymode}
    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium">
                    ₹{item.amount}
                    </span>
                  </td>
                  
                  <td className="text-center">
                    <div className="order-actions">
                      {/* <EditButton onEdit={() => handleEditModel(item)} /> */}
                      <DeleteButton
                       onDelete={() => openDeleteDialog(item)}
                        />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No data Available
                </td>
              </tr>
            )}
          </tbody>
      </table>
    
      {isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name=""
        />
      )}
    </div>
  );
};

export default ExprnseTableData;
