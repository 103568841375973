
import React from 'react';

import { Link, useLocation } from 'react-router-dom';
function Menu() {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div>
      <div className="primary-menu">
        <nav className="navbar navbar-expand-lg align-items-center">
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header border-bottom">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/v1/logo-icon.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                </div>
                <div>
                  <h4 className="logo-text">Syndron</h4>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-start flex-grow-1 gap-1">
                <li className="nav-item dropdown">
                <Link
                    className={`nav-link ${currentPath === '/dashboard' ? 'active' : ''}`}
                    to="/dashboard"
                  >
                    <div className="parent-icon">
                      {/* <i className="bx bx-home"></i> */}
                      <i className="ng-ng-home-icon"></i>
                    </div>
                    <div className="menu-title d-flex align-items-center">
                      Dashboard
                    </div>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                <Link
                    className={`nav-link ${currentPath === '/sales' ? 'active' : ''}`}
                    to="/sales"
                  >
                    <div className="parent-icon">
                      <i className="ng-ng-negobill_icon"></i>
                    </div>
                    <div className="menu-title d-flex align-items-center">
                      Sales
                    </div>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                <Link
                    className={`nav-link ${currentPath === '/expenses' ? 'active' : ''}`}
                    to="/expenses"
                  >
                    <div className="parent-icon">
                      <i className="ng-ng-expence-icon"></i>
                    </div>
                    <div className="menu-title d-flex align-items-center">
                      Expenses
                    </div>
                  </Link>
                </li>
{/*                
                <li className="nav-item dropdown">
                <Link
                    className={`nav-link ${currentPath === '/boxcash' ? 'active' : ''}`}
                    to="/boxcash"
                  >
                <div className="parent-icon"><i className='ng-ng-card-swipe-icon'></i>
                        </div>
                            <div className="menu-title d-flex align-items-center">Box Cash</div>
                </Link>
                    </li> */}
 {/*

                    <li className="nav-item dropdown">
                    <Link
                    className={`nav-link ${currentPath === '/paylater' ? 'active' : ''}`}
                    to="/paylater"
                  >
                             <div className="parent-icon"><i className='ng-ng-card-paylater-icon'></i>
                      </div>
                          <div className="menu-title d-flex align-items-center">Pay Later</div>
                     </Link>
                 </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Menu;
