import React, { useEffect, useRef, useState } from 'react'
import { getAccessStoreToken,  getAccessToken,  getRefreshStoreToken, getStoreEmail, getStoreId, getStoreTitle,  setAccessStoreToken, setRefreshStoreToken, setStoreEmail, setStoreId, setStoreTitle } from '../../utils/CookieUtiles';
import FullPageLoader from '../../reusable/buttons/PageReload';
// import logo from '../../styles/images/logo/logo.png';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
function AddProfile({setProfile,setLoggedIn}) {
    const [loading, setLoading] = useState(true);
    const [buttonloading, setButtonLoading] = useState(false);
    const [profileError, setProfileError] = useState(false);
    const [isTitle, setTitle] = useState("");
    const navigate = useNavigate();
    const authToken=getAccessToken();

  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const profileNameRef = useRef(null);
  // const handleCheckProfile = async () => {
  //   setLoading(true);
  //   const url = `${base_apiUrl}/auth-store`;

  //   const options = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   };

  //   try {
  //     const response = await fetch(url, options);
  //     const data = await response.json();
  //     console.log(data);
  //     if (response.status === 404) {
  //       console.log("No store found, redirecting to /addprofile.");
  //       window.location.href = "/addprofile"; 
  //       return;  
  //     }
     
  //     console.log("Profile data success", data);
  //     if (response.ok && data.results && data.results.accessToken) {
  //       setAccessStoreToken(data.results.accessToken);
  //       setRefreshStoreToken(data.results.refreshToken);
  //       console.log("AccessStoreToken", getAccessStoreToken());
  //       console.log("RefreshStoreToken", getRefreshStoreToken());
        
       
  //     }
  
  //     console.log(getStoreEmail(),getStoreTitle(),getStoreId())
  //     setProfile(true);
  //     setLoading(false);
  //     navigate("/dashboard")
      
  //   } catch (error) {
  //     console.error("Error adding barcode:", error);
  //     setLoading(false);
  //   }
  // };


  const handleLogoutProfile = () => {
 
    setLoggedIn(false);
    navigate('/login');
  };


  const handleAddProfile = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    const url = `${base_apiUrl}/add-store`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
            title:isTitle
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
console.log("data store",data)
      if (!response.ok) {
        console.log("Something When Wrong .");

        setButtonLoading(false);
        return;
      }
      if (response.ok && data.results && data.results.accessToken) {
        setAccessStoreToken(data.results.accessToken);
        setRefreshStoreToken(data.results.refreshToken);
        console.log("AccessStoreToken", getAccessStoreToken());
        console.log("RefreshStoreToken", getRefreshStoreToken());
        setProfile(true);
        navigate("/dashboard")
        setButtonLoading(false);
        setTitle("")
      } else {
        setProfileError("Something Wrong .");
      }
    
     
 
    } catch (error) {
      console.error("Error adding product:", error);
      setButtonLoading(false);
     
    }
  };


  useEffect(() => {
    
    const delayTimer = setTimeout(() => {
     
      setLoading(false); 
    }, 1000);

   
    return () => clearTimeout(delayTimer);
  }, []);

  return (
    <div>
    {loading ? (
      <FullPageLoader/>
    ) : (
    
      <div className="wrapper"><div className='p-2'>
    <button type="button" id="addproductbtn"  onClick={handleLogoutProfile} className="btn btn-dark text-right" fdprocessedid="wwcukh">
      <i className="ng-ng-logout-icon"></i> Logout</button>
    <div>
   
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="p-4">
                      
                    <div className="mb-3 text-center">
                        <img   src="/assets/images/v1/logo-dark.png" width="100" alt="" />
                      </div>
                      <div className="text-center mb-4">
                        {/* <h5 className="" >Admin Portal</h5> */}
                       
                        {/* <p className="mb-0">Login to your account</p> */}
                      </div>
                      <div className="form-body">
                        <form className="row g-3" id="ownerLoginForm"
                         onSubmit={handleAddProfile}
                         
                         >
                          <div className="col-12">
                            <label htmlFor="username" className="form-label">
                              Enter Your Business name <span className="text-danger">*</span>
                            </label>
                            
                            <input
                              type="text"
                              className="form-control LogInTindex"
                              id="username"
                              value={isTitle}
                              onChange={(e) => setTitle(e.target.value)}
                              autoComplete="off"
                              ref={profileNameRef}
                            />  
                            
                             {profileError && <div className="error-message invalid-feedback" >{profileError}</div>}
                          </div>
                         
                          
                

                          {buttonloading ? (<div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }}
                              >
                                 Processing.. <CircularProgress style={{color:'white', width:'17px',height:'auto'}}/>
                              </button>
                            </div>
                          </div>  ):( <div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }}
                              >
                                Rigister
                              </button>
                            </div>
                          </div>)}

                         
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    )}
  </div>
  )
}

export default AddProfile
