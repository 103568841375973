import React, { useState } from "react";
import EditButton from "../EditIcon";
import DeleteButton from "../DeleteIcon";
import EditCutomerModel from "../../menuList/EditCustomer";
import DeleteDialog from "../buttons/Deletedialog";

import { Skeleton } from "@mui/material";

const CustomerTable = ({ data, onEdit, onDelete,updateload,loading }) => {
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
 
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-label-green";
      case "inactive":
        return "bg-label-danger";
      default:
        return "bg-label-secondary";
    }
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedCustomerData(null);
  };
  const openEditModal = (customer) => {
    setSelectedCustomerData(customer);
    setIsDialogOpen(true);
  };

  const handleCustomeUpdte = (updatedCustomerData) => {
    onEdit(updatedCustomerData);
    handleCloseDialog();
  };

  const confirmDelete = () => {
    onDelete(selectedCustomerData._id);
    setIsDeleteDialogOpen(false);
  };
  const openDeleteDialog = (customer) => {
    setSelectedCustomerData(customer);
    setIsDeleteDialogOpen(true);
  };




  return (<>

    <div className="table-responsive ">
      <table className="table align-middle mb-0">
        <thead className="table-light text-center">
          <tr>
            <th className="text-center">S.No</th>
            <th className="text-center">Name</th>
            <th className="text-center">Phone</th>
            <th className="text-center">Email</th>
            <th className="text-center">Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
   
        <tbody>
        {loading ? (
                      [...Array(5)].map((_, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <Skeleton variant="text" width={200} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={400} />
                          </td>
                         
                          <td className="text-center">
                            <Skeleton variant="center" width={300} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={70} />
                          </td>
                        </tr>
                      ))
                    ) :Array.isArray(data) &&
                    data.length > 0 ? (
            data.map((item, index) => (
              <tr key={item.id || index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  <span className="text-heading fw-medium text-truncate" style={{ textTransform: 'capitalize' }}>
                    {item.name}
                  </span>
                </td>
                <td className="text-center">{item.phone}</td>
                <td className="text-center">{item.email}</td>

                <td className="text-center">
                  {item.status && (
                    <span
                      className={`badge rounded-pill ${getColor(item.status)}`}
                    >
                      {item.status.charAt(0).toUpperCase() +
                        item.status.slice(1).toLowerCase()}
                    </span>
                  )}
                </td>

                <td className="text-center">
                  <div className="order-actions">
                    <EditButton onEdit={() => openEditModal(item)} />
                    <DeleteButton onDelete={() => openDeleteDialog(item)} />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>



      {isDialogOpen && (
        <EditCutomerModel
          data={selectedCustomerData}
          onClose={handleCloseDialog}
          onEdit={handleCustomeUpdte}
          updateload={updateload}
         
        />
      )}

{isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name=""
        />
      )}


    </div>
    </>
  );
};

export default CustomerTable;
