
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import '../layout/header.css'
import { getStoreEmail, getStoreTitle } from '../utils/CookieUtiles';

function Header({ toggleSideMenu,setLoggedIn }) {
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();
 const title=getStoreTitle();
 const email=getStoreEmail();
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleLogout = () => {
    setLoggedIn(true);
    navigate('/');
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  return (
    <div>
      <div className="wrapper toggled">
        <header>
          <div className="topbar d-flex align-items-center">
            <nav className="navbar navbar-expand gap-3">
              <div className="topbar-logo-header d-none d-lg-flex">
                <div className="">
                  <img
                    src="/assets/images/v1/logo-img.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                </div>
              </div>
              <div className="mobile-toggle-menu" onClick={toggleSideMenu}>
                <i className="bx bx-menu"></i>
              </div>

              <div
                className="search-bar d-none"
                data-bs-toggle="modal"
                data-bs-target="#SearchModal"
              >
                <button
                  className="btn d-flex align-items-center"
                  onClick={() =>
                    (document.getElementById("SearchModal").style.display =
                      "block")
                  }
                >
                  <i className="bx bx-search"></i> Search
                </button>
              </div>
              <div className="top-menu ms-auto">
                <ul className="navbar-nav align-items-center gap-1">
                  <li
                    className="nav-item mobile-search-icon d-flex d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#SearchModal"
                  >
                    <button
                      className="nav-link"
                      onClick={() =>
                        (document.getElementById("SearchModal").style.display =
                          "block")
                      }
                    >
                      <i className="bx bx-search"></i>
                    </button>
                  </li>
                  <li className="nav-item dropdown dropdown-language d-none">
                    <button
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        src="/assets/images/v1/county/02.png"
                        width="22"
                        alt=""
                      />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      {/* Add dropdown items here */}
                    </ul>
                  </li>
                  <li className="nav-item dark-mode d-none">
                    <button className="nav-link dark-mode-icon">
                      <i className="bx bx-moon"></i>
                    </button>
                  </li>

                  <li className="nav-item dropdown dropdown-app d-none">
                    <button
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <i className="bx bx-grid-alt"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end p-0">
                      <div className="app-container p-2 my-2">
                        {/* Add app container items here */}
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown dropdown-large d-none">
                    <button
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                      data-bs-toggle="dropdown"
                    >
                      <span className="alert-count">7</span>
                      <i className="bx bx-bell"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <div className="msg-header">
                        <p className="msg-header-title">Notifications</p>
                        <p className="msg-header-badge">8 New</p>
                      </div>
                      <div className="header-notifications-list">
                        {/* Add notifications list here */}
                      </div>
                      <div className="text-center msg-footer">
                        <button className="btn btn-primary w-100">
                          View All Notifications
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown dropdown-large d-none">
                    <button
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                      data-bs-toggle="dropdown"
                    >
                      <span className="alert-count">8</span>
                      <i className="bx bx-shopping-bag"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <div className="msg-header">
                        <p className="msg-header-title">My Cart</p>
                        <p className="msg-header-badge">10 Items</p>
                      </div>
                      <div className="header-message-list">
                        {/* Add cart items here */}
                      </div>
                      <div className="text-center msg-footer">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h5 className="mb-0">Total</h5>
                          <h5 className="mb-0 ms-auto">$489.00</h5>
                        </div>
                        <button className="btn btn-primary w-100">
                          Checkout
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="user-box dropdown px-3">
                <div className="dropdown">
                  <Link
                    to="#"
                    className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                     src="/assets/images/newlog.png"
                      // src="/assets/images/v1/avatars/avatar-2.png"
                      className="user-img"
                      alt="user avatar"
                    />
                    <div className="user-info">
                      <p className="user-name mb-0">{title}</p>
                      <p className="designattion mb-0">{email}</p>
                    </div>
                  </Link>

                  {/* Dropdown Menu */}
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className={`dropdown-item ${
                          hoveredItem === "profile" ? "hovered" : ""
                        }`}
                        to="/profile"
                        onMouseEnter={() => handleMouseEnter("profile")}
                        onMouseLeave={handleMouseLeave}
                      >
                        <i className="bx bx-user fs-5"></i>
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          hoveredItem === "settings" ? "hovered" : ""
                        }`}
                        to="/settings"
                        onMouseEnter={() => handleMouseEnter("settings")}
                        onMouseLeave={handleMouseLeave}
                      >
                        <i class="bx bx-cog fs-5"></i>
                        Settings
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          hoveredItem === "logout" ? "hovered" : ""
                        }`}
                        to="#"
                        onMouseEnter={() => handleMouseEnter("logout")}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleLogout}
                      >
                        <i class="bx bx-log-out-circle"></i>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
