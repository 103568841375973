import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getAccessToken,
  removeAccessToken,
} from "../utils/CookieUtiles";
import useErrorHandle from "../error/ErrorHandling";
import { useNavigate } from "react-router-dom";
import { fetchProfileData } from "../utils/CreateApi";

const Login = ({ setLoggedIn, setProfile }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [isOtperror, setOtpError] = useState("");
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL_LOGIN_NEGOBILL;
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleError = useErrorHandle();

  const startTimer = () => {
    setTimer(30);
    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(id);
          removeAccessToken();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const verifyOtp = async () => {
    setLoading(true);
    setEmail("");
    setError("");
    try {
      const verifyToken = getAccessToken();

      const response = await fetch(`${apiUrl}auth-email-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${verifyToken}`,
        },
        body: JSON.stringify({ verifycode: otp }),
      });
      if (!response.ok) {
        console.log("response not ok");
        // await handleError(response);
      }
      const data = await response.json();

      if (response.ok && data.results && data.results.accessToken) {
        setAccessToken(data.results.accessToken);
        setRefreshToken(data.results.refreshToken);
        console.log("access", getAccessToken());
        console.log("refresh", getRefreshToken());
        fetchProfileData(getAccessToken(), navigate, setLoggedIn, setProfile);
      } else {
        setOtpError("Invalid OTP.");
      }
    } catch (error) {
      console.error("Verification error:", error.message);
      setError("An error occurred during OTP verification.");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("This field not be empty.");
      return;
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    sendOtp();
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();

    if (!otp) {
      setOtpError("This field cannot be empty.");
      return;
    } else if (otp.length < 6) {
      setOtpError("OTP must be 6 digits.");
      return;
    }
    verifyOtp();
  };

  const sendOtp = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${apiUrl}auth-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok && data.results.verifyToken) {
        setAccessToken(data.results.verifyToken);
        console.log("Verify token set in cookies:", getAccessToken());
        setStep(2);
        console.log("success");
        startTimer();
      } else {
        setError("Failed to send OTP.");
      }
    } catch (error) {
      console.log("failed");
      console.error("Verification error:", error.message);
      setError("An error occurred while sending the OTP.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (otp.length === 6) {
  //     handleOtpSubmit(new Event("submit"));
  //   }
  // }, [otp]);

  return (
    <div>
      <div className="wrapper">
        <div className="section-authentication-cover">
          <div className="">
            <div className="row g-0">
              <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
                <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0">
                  <div className="card-body">
                    <img
                      src="/assets/images/v1/login-images/auth-login-image.png"
                      className="img-fluid"
                      width="600"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
                <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
                  <div className="card-body p-sm-5">
                    <div className="p-3 ">
                      <div className="text-center">
                        <img
                          src="/assets/images/v1/logo-icon.png"
                          width="60"
                          alt=""
                        />
                      </div>
                      {step === 1 && (
                        <>
                          <h4 className="text-center mt-5 font-weight-bold">
                            Welcome to Nego Bill Portal
                          </h4>
                          <p className="text-center text-muted">
                            By continuing with your registered email ID
                          </p>
                          <form
                          // onSubmit={handleEmailSubmit}
                          >
                            <div className="form-group mt-4">
                              <label
                                htmlFor="text"
                                className="text-left me-1"
                                style={{ marginBottom: "4px" }}
                              >
                                Email
                              </label>
                              <span className=" text-left text-danger">*</span>
                              <input
                                type="text"
                                id="email"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {error && (
                                <div className="error-message invalid-feedback text-left">
                                  {error}
                                </div>
                              )}
                            </div>
                            {/* {loading ? (
                              <button className="btn btn-primary w-100 my-3">
                                <span className="me-2">Generating OTP...</span>
                                <CircularProgress
                                  style={{
                                    color: "white",
                                    width: "17px",
                                    height: "auto",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary w-100 my-3"
                                onClick={handleEmailSubmit}
                              >
                                Sign In
                              </button>
                            )} */}










<div className="col-12 py-2">
                              <div className="d-grid">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{ border: "none", outline: "none" }}
                                  disabled={loading}
                                  onClick={handleEmailSubmit}
                                >
                                  {loading ? (
                                    <>
                                     Generating OTP...{" "}
                                      <CircularProgress
                                        style={{
                                          color: "white",
                                          width: "17px",
                                          height: "auto",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>    Sign In</>
                                  )}
                                </button>
                              </div>
</div>





                          </form>
                          {/* <p className="text-center text-muted">
                            Don't have an account yet?
                            <a
                              href="/register"
                              className="text-primary"
                              style={{ paddingLeft: "4px" }}
                            >
                              Register here
                            </a>
                          </p> */}

                          <div className="d-flex align-items-center justify-content-center mt-2 position-relative">
                            <hr className="flex-grow-1" />
                            <span className="mx-2 text-muted">
                              OR SIGN IN WITH
                            </span>
                            <hr className="flex-grow-1" />
                          </div>

                          <a
                            className="btn btn-white w-100 my-3 waves-effect waves-light"
                            href="/dashboard"
                          >
                            <img
                              className="me-2"
                              src="/assets/images/v1/google-icon.svg"
                              width="18"
                              alt=""
                            />{" "}
                            Login in with Google
                          </a>
                        </>
                      )}

                      {step === 2 && (
                        <>
                          <h4 className="text-center mt-5 font-weight-bold">
                            Verify OTP
                          </h4>
                          <p className="text-center text-muted">
                            Enter the OTP sent to your email
                          </p>
                          <form onSubmit={handleOtpSubmit}>
                            <div className="form-group mt-4">
                              <label
                                htmlFor="otp"
                                className="text-left"
                                style={{ marginBottom: "4px" }}
                              >
                                <span className="me-1"> OTP</span>
                              </label>
                              <span className=" text-left text-danger">*</span>
                              <input
                                type="text"
                                id="otp"
                                className="form-control"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                maxLength="6"
                              />
                            </div>
                            {timer > 0 && (
                              <p className="mt-3 text-center error-message invalid-feedback">
                                {timer}s
                              </p>
                            )}

                            {/* ) : (
                              <div
                               className="error-message invalid-feedback text-center "
                                onClick={handleEmailSubmit}
                              >
                                Resend OTP
                              </div>
                            )} */}

                            {isOtperror && (
                              <div className="error-message invalid-feedback ">
                                {isOtperror}
                              </div>
                            )}
                            {/* {loading ? (<div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }}
                                disabled="loading"
                              >
                                 Processing.. <CircularProgress style={{color:'white', width:'17px',height:'auto'}}/>
                              </button>
                            </div>
                          </div>  ):( <div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }} 
                               
                              >
                                Submit
                              </button>
                            </div>
                          </div>)} */}

                            <div className="col-12 py-2">
                              <div className="d-grid">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{ border: "none", outline: "none" }}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <>
                                      Processing..{" "}
                                      <CircularProgress
                                        style={{
                                          color: "white",
                                          width: "17px",
                                          height: "auto",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>Submit</>
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
