import React, { useEffect, useState } from "react";
import CustomButtonAdd from "../reusable/CusstomButtonAdd";
import SearchBox from "../reusable/salesButton/SearchBox";

import CustomerTable from "../reusable/tables/CustomerTable";
import CustomerModal from "./AddCustomer";
import useErrorHandle from "../error/ErrorHandling";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import AddNegoButton from "../reusable/AddNegoButton";
import { getStoreId } from "../utils/CookieUtiles";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Customer({ authToken }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCustomerData, setCustomerData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchTerm, setSearchTerm] = useState("")
  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const [buttonloading, setButtonLoading] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleError = useErrorHandle();
  const storeid=getStoreId();
  const handleSnackbarClose = ( reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAdd = async (newCustomer) => {
    setButtonLoading(true);
    const url = `${base_apiUrl}/add-customer`;

    const payload = {
      storeid: storeid,
      name: newCustomer.name,
      phone: newCustomer.phone,
      email: newCustomer.email,

      addressline1: newCustomer.addressline1,
      addressline2: newCustomer.addressline2,
      state: newCustomer.state,
      statecode: newCustomer.statecode,

      district: newCustomer.district,
      country: newCustomer.country,
      pincode: newCustomer.pincode,
      cinnumber: newCustomer.cinnumber,
      gstnumber: newCustomer.gstnumber,
      pannumber: newCustomer.pannumber,
    };

    if (newCustomer.phone) {
      payload.phone = newCustomer.phone;
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      const data = await response.json();
      if (!response.ok) {
        setSnackbarSeverity("error");

        setSnackbarMessage(data.message || "An error occurred");
        await handleError(response);

        setButtonLoading(false);
        return;
      }
      setCustomerData((prevShops) => {
        
        return [...(prevShops || []), data.results];
      });

      setSnackbarSeverity("success");
      setSnackbarMessage("Added successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error adding shop:", error);
      setSnackbarSeverity("An error occurred. Please try again.");

      setButtonLoading(false);
    }
  };


  const handleEdit = async (updatedCustomer) => {
    setButtonLoading(true);
    if (!updatedCustomer._id) {
        console.error("Error: shopid is required but not provided.");
        return;
    }

    console.log("cutomer id:", updatedCustomer._id);
    const url = `${base_apiUrl}/update-customer`;

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          storeid: storeid,
          customerid:updatedCustomer._id,
          name: updatedCustomer.name || undefined,
          phone: updatedCustomer.phone|| undefined,
          email: updatedCustomer.email|| undefined,
          city: updatedCustomer.city|| undefined,
    
          addressline1: updatedCustomer.addressline1|| undefined,
          addressline2: updatedCustomer.addressline2|| undefined,
          state: updatedCustomer.state|| undefined,
          statecode: updatedCustomer.statecode|| undefined,
    
          district: updatedCustomer.district|| undefined,
          country: updatedCustomer.country|| undefined,
          pincode: updatedCustomer.pincode|| undefined,
          cinnumber: updatedCustomer.cinnumber|| undefined,
          gstnumber: updatedCustomer.gstnumber|| undefined,
          pannumber: updatedCustomer.pannumber|| undefined,
        }),
    };

    try {
        const response = await fetch(url, options);
        console.log("Response:", response);

        if (!response.ok) {
            const errorData = await response.json();
            setSnackbarMessage(errorData.message || 'An error occurred');
            await handleError(response);
        }

        const data = await response.json();
        console.log("Updated Shop data:", data);

        setCustomerData((prevCustomerData) => {
            return prevCustomerData.map((customer) =>
              customer._id === data.results._id ? data.results : customer
            );
        });
   
        setSnackbarSeverity('success');
        setSnackbarMessage("Customer Data updated successfully!");
        setSnackbarOpen(true);
        setButtonLoading(false);
      
    } catch (error) {
        console.error('Error customer data:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('Error updating customer data.',error);
        setSnackbarOpen(true);
        setButtonLoading(false);
    }
};
 
  const handleDelete = async (CustomerId) => {
    console.log("deleteid",CustomerId)
    const url = `${base_apiUrl}/delete-customer`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ storeid: storeid, customerid : CustomerId }),
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
        return;
      }
  
 
      setCustomerData((prevCustomerData) =>
        prevCustomerData.filter((customer) => customer.CustomerId !== CustomerId)
      );
      fetchCustomers();
      setSnackbarSeverity("success");
      setSnackbarMessage("Deleted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting product:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error deleting product.");
      setSnackbarOpen(true);
    }
  };



  const fetchCustomers = async () => {
    setLoading(true);
    const url = `${base_apiUrl}/list-customer`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ searchtxt:searchTerm ||'', storeid: storeid,})
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setCustomerData(data.results);
      console.log("data.results", data.results);
    } catch (error) {
      console.error("Error fetching shops:", error);
    } finally {
      setLoading(false);
      console.log("false load");
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [searchTerm]);
  return (
    <div>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="page-breadcrumb d-flex align-items-center ">
            <div class="breadcrumb-title pe-3">Customers</div>
                     </div>
                     <div className="row">
        <div className="col">
          <div className="card radius-10 mb-0 mt-3">
            <div className="card-header justify-content-between align-items-center p-0">
              <div className="card-body px-3 py-3 ">
                <div className="d-flex align-items-center justify-content-between gap-3">
               
                  <div className="col-6">
                    <div className="position-relative">
                      <SearchBox
                        placeholder="Search customers by name, phone etc..."
                        buttonIcon="ng-ng-customers-search-icon"
                        inputValue={searchTerm}
                        onInputChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  
                 
                  <div>
                  <AddNegoButton
                text="Add Customer"
                icon="ng-ng-customers-add-icon"
                onClick={handleOpenModal}
                className="btn-primary mt-2 mt-lg-0"
              />
                  </div>
                </div>
              </div>
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>

                  <CustomerModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onAdd={handleAdd}
                    buttonloading={buttonloading}
                  />

                  <CustomerTable
                                   loading={loading}
                    data={isCustomerData}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customer;
