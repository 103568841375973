// PayLaterTable.js
import React, { useState } from 'react';
import ViewButton from '../ViewIcon';
import CancelButton from '../CancelButton';
import AddButton from '../AddButton';

const PayLaterTable = ({ data, handleAdd }) => {
  const [isModalViewOpen, setModalViewOpen] = useState(false);
  
  const handleView = () => {
    setModalViewOpen(true);
  };
  const handleCloseModal = () => setModalViewOpen(false);
  return (
    <>
      <div className="table-responsive mt-3">
        <table className="table align-middle mb-0">
          <thead className="table-light text-center">
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <th>Time</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Due Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{item.date}</td>
                <td className="text-center">{item.time}</td>
                <td className="text-center">
                  <div className="d-flex flex-column">
                    <span className="text-nowrap text-heading fw-medium">
                      {item.name}
                    </span>
                  </div>
                </td>
                <td className="text-center">{item.phone}</td>
                <td className="text-center">
                  <h6 className={`mb-0 ${item.dueAmount.startsWith('-') ? 'text-pink' : ''}`}>
                    {item.dueAmount}
                  </h6>
                </td>
                <td className="text-center">
                  <ViewButton
                    onClick={() => handleView()}  // Use a function reference
                    iconClass="bx bx-show"
                    labelClass="btn-label-primary shadow-none"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isModalViewOpen && (
          <div className="modal show" id="HistoryModal" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title">Transaction History</h6>
                  <button type="button" className="btn-close"  onClick={handleCloseModal} data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-light">
               
                </div>
                <div className="modal-footer d-block">
                <CancelButton
                                buttonText="Cancel"
                                buttonIcon="bx bx-x"
                                onClick={handleCloseModal}
                                additionalClasses="btn-outline-secondary text-left"
                              />
                              <AddButton label="Add"
        iconClass="bx bx-check"
        buttonClass="btn btn-primary text-right"
        onClick={handleAdd}/>
                 
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PayLaterTable;
