// utils/CookieApi.js

import { getAccessStoreToken,  getRefreshStoreToken , removeAccessStoreToken,  setAccessStoreToken, setRefreshStoreToken, setStoreId,  } from "./CookieUtiles";



const apiUrl = process.env.REACT_APP_BASE_URL;

export const createToken = async (navigate,setLoggedIn) => {
    console.log("create token");
    removeAccessStoreToken();
    try {
        const refreshStoreToken = getRefreshStoreToken();
        if (!refreshStoreToken) {
            setLoggedIn(false)
            navigate('/addprofile');
            console.log("log")
            return false;
        }

        const response = await fetch(`${apiUrl}/create-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshStoreToken}`,
            },
        });
console.log("referesh",refreshStoreToken)
        if (response.status === 401) {
            setLoggedIn(false)
            console.log("401")
            navigate('/addprofile');
            return false;
        }

        const data = await response.json();
        console.log(data)
        if (response.ok && data.results && data.results.accessToken) {
            setAccessStoreToken(data.results.accessToken);
           
            verifiedToken(navigate);
            return true;
        } else {
            console.log("hi")
            setLoggedIn(false)
            navigate('/addprofile');
            return false;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        navigate('/addprofile');
        return false;
    }
};

export const verifiedToken = async (navigate) => {
    console.log("Auth token");
    try {
        const accessToken = getAccessStoreToken();
        console.log("verify auth1",accessToken)
        const response = await fetch(`${apiUrl}/auth-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/addprofile');
            }
            return;
        }

        const data = await response.json();
        if (response.ok && data.status && data.code === "TOKEN_VERIFIED") {        
           

            console.log("User details:", data.results);
            // setUserName(data.results.shopcode);  
            // console.log("shop-code from cookie:", getUserName());
        } else {
            
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
        }
    } catch (error) {
              console.error("Error authenticating token:", error);
        console.log("Error authenticating token:", error);
        navigate('/login');
    }
};


export const viewstore = async (navigate,setLoggedIn) => {
   
    try {
       
        const response = await fetch(`${apiUrl}/view-store`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessStoreToken}`,
            },
        });
console.log("referesh",getAccessStoreToken)
        if (response.status === 404) {
            setLoggedIn(false)
            console.log("401")
            navigate('/login');
            return false;
        }

        const data = await response.json();
        console.log(data)
        if (response.ok && data.results ) {
            setStoreId(data.results._id);
           
           
            return true;
        } else {
            console.log("view")
            setLoggedIn(false)
            navigate('/login');
            return false;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        navigate('/login');
        return false;
    }
};


export const fetchProfileData = async (accessToken, navigate, setLoggedIn, setProfile) => {
    const url = `${apiUrl}/auth-store`;
  
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      
      console.log("Profile data received:", data);  
  
      if (response.status === 404) {
        console.log("No store found, redirecting to /addprofile.");
        setLoggedIn(false);
        navigate('/addprofile');
        return null; 
      }
  
      if (response.ok && data.results && data.results.accessToken) {
        setAccessStoreToken(data.results.accessToken);
        setRefreshStoreToken(data.results.refreshToken);
  
        console.log("Profile data success", data);
  
        setProfile(true);
        setLoggedIn(true);  
        navigate('/dashboard');
        return data;  
      } else {
        return null;
      }
  
    } catch (error) {
      console.error("Error fetching profile data:", error);
      return null;
    }
  };
  
// export const fetchProfileData = async (accessToken,navigate,setLoggedIn,setProfile) => {
//     const url = `${apiUrl}/auth-store`;
  
//     const options = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };
  
//     try {
//       const response = await fetch(url, options);
//       const data = await response.json();
      
//       if (response.status === 404) {
//         console.log("No store found, redirecting to /addprofile.");
//         setLoggedIn(false);
//         navigate('/addprofile');
//         return null; 
//       }
  
//       if (response.ok && data.results && data.results.accessToken) {
        
//         setAccessStoreToken(data.results.accessToken);
//         setRefreshStoreToken(data.results.refreshToken);
//         setProfile(true);
//         navigate('/dashborad');
//         console.log("Profile data success", data);
//         return data;  
//       } else {
//         return null;
//       }
  
//     } catch (error) {
//       console.error("Error fetching profile data:", error);
//       return null;
//     }
//   };