import React, {  useEffect, useRef, useState } from 'react';

import AddButton from '../reusable/AddButton';
import CancelButton from '../reusable/CancelButton';
import StateDropdown from '../reusable/StateDropDown';
import ButtonWithProgress from '../reusable/ButtonWithProgress';


const EditCutomerModel = ({ data, onClose, onEdit ,updateload}) => {

  const [errors, setErrors] = useState({});
  const customerNameRef = useRef(null);
  const customerPhoneRef = useRef(null);
  const customerEmailRef=useRef(null);
  const [customerData, setCustomer] = useState({
    name: "",
    phone: "",
    email: "",

    addressline1: "",
    addressline2:"",
    state: "",
    statecode: "",
city:"",
    district: "",
    country: "",
    pincode: "",
    cinnumber: "",
    gstnumber: "",
    pannumber: "",
  });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue;
    if (
      name === "gstnumber" ||
      name === "pannumber" ||
      name === "cinnumber" ||
      name === "statecode" ||
      name === "pincode"
    ) {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }

    setCustomer((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));

    
  };
  const stopEventPropagation = (e) => {
    e.stopPropagation();  
  };





  const resetForm = () => {
    setCustomer({
      name: "",
      phone: "",
      email: "",
      addressline1: "",
      addressline2: "",
      state: "",
      statecode: "",
      district: "",
      country: "",
      pincode: "",
      cinnumber: "",
      gstnumber: "",
      pannumber: "",
    });
    setErrors({});
  };
  



  const handleClose = () => {
    resetForm();
    onClose();
    
  };


  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!customerData.name) {
      newErrors.name = "This field must not be empty.";
      console.log("name eror", newErrors.name)
      valid = false;
      customerNameRef.current.focus();
    }
  
     else if   (!customerData.phone) {
      newErrors.phone = "This field must not be empty.";
      valid = false;
      customerPhoneRef.current.focus();
    } else if (customerData.phone.length !== 10) {
      newErrors.phone = "Phone Number must be 10 digits.";
      customerPhoneRef.current.focus();
      valid = false;
    } else if (!/^\d{10}$/.test(customerData.phone)) {
      newErrors.phone = "Phone Number must be numeric.";
      customerPhoneRef.current.focus();
      valid = false;
    }else if (customerData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerData.email)) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
      customerEmailRef.current.focus();
    }

    if (customerData.gstnumber && customerData.gstnumber.length > 1000) {
      newErrors.gstnumber = "GST Number must not exceed 1000 characters.";
      valid = false;
    }
    else if (customerData.addressline1 && customerData.addressline1.length > 2000) {
      newErrors.addressline1 =
        "Address Line 1 must not exceed 2000 characters.";
      valid = false;
    } else if (customerData.addressline2 && customerData.addressline2.length > 2000) {
      newErrors.addressline2 =
        "Address Line 2 must not exceed 2000 characters.";
      valid = false;
    }else if (customerData.pincode && customerData.pincode.length < 6) {
      newErrors.pincode = "Pincode must be at least 6 characters long.";
      valid = false;
    }
      else if (customerData.pincode && isNaN(customerData.pincode)) {
        newErrors.pincode = "Pincode must be a valid number.";
        valid = false;
      }
  

    setErrors(newErrors);
    return valid;
  };

  const handleEdit = () => {
   
    if (!validateFields()) {
      return;
    }
    setErrors("");
   
    onEdit(customerData);
    resetForm(); 
  };

  useEffect(() => {
    if (data) {
      setCustomer(data);
    }
  }, [data]);

  return (
    <div>
  <div className="modal-backdrop show" onClick={handleClose}  ></div>
  <div className="modal show" id="EditCutomerModel"tabIndex="-1" aria-modal="true" role="dialog" onClick={handleClose} style={{display: "block"}}>
  
    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" onClick={stopEventPropagation}>
      <div className="modal-content">
        <div className="modal-header">
          <h6 className="modal-title">Edit Customer</h6>
          <button type="button" className="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close" fdprocessedid="jgxcje"></button>
        </div>
        <div className="modal-body bg-light">
          <form>
            <div className="card">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <label htmlFor="FisrtName" className="form-label">Name 
                      <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" name="name"
                      autoComplete="off"
                      value={customerData.name}
                      onChange={handleChange}
                      ref={customerNameRef}/>
                       {errors.name && (
                      <div className="error-message invalid-feedback" > 
                        {errors.name}
                     </div>
                    )} 
                  </div>
                  
                  <div className="col-lg-6">
                    <label htmlFor="LastName" className="form-label">Phone <span className="text-danger">*</span></label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon41">+91</span>
                      <input type="text" className="form-control"
                       autoComplete="off"
                       name="phone"
                       ref={customerPhoneRef}
                       value={customerData.phone}
                       onChange={(e) => {
                         const value = e.target.value;

                         if (/^\d{0,10}$/.test(value)) {
                           handleChange(e);
                         }
                       }}/>
                     
                    </div>
                    {errors.phone && (
                      <div className="error-message invalid-feedback" > 
                        {errors.phone}
                     </div>
                    )} 
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion accordion-flush">
							<div className="accordion-item">
								<h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="5b1j1a">
                    Billing Details (Optional)
                  </button>
                </h2>
								<div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Address Line 1</label>
                          <textarea type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="addressline1"
                           value={customerData.addressline1}
                          id="bsValidation1"></textarea>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Address Line 2</label>
                          <textarea type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="addressline2"
                           value={customerData.addressline2}
                          id="bsValidation1"></textarea>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">City</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="city"
                           value={customerData.city}
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">District</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="district"
                           value={customerData.district}
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">State</label>

                          <StateDropdown  stateChange={handleChange} 
                          value={customerData.state}/>
                          
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-3">
                          <label htmlFor="bsValidation1" className="form-label">Country</label>
                          <input type="text" disabled="" className="form-control"
                          
                          autoComplete="off"
                          onChange={handleChange}
                          name="country"
                          value={customerData.country} id="bsValidation1"/> 
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-3">
                          <label htmlFor="bsValidation1" className="form-label">Pincode</label>
                          <input type="text"
                                maxLength={6}
                                className="form-control"
                                autoComplete="off"
                                onChange={handleChange}
                                name="pincode"
                                value={customerData.pincode}/>
                                  {errors.pincode && (
                                <div className="error-message invalid-feedback">
                                  {errors.pincode}
                                </div>
                              )}
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Email</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="email"
                           ref={customerEmailRef}
                           value={customerData.email}
                          
                          id="bsValidation1"/>

{errors.email && (
                                <div className="error-message invalid-feedback">
                                  {errors.email}
                                </div>
                              )}
                          <div className="valid-feedback"></div>
                        </div>
                      </div>
                  </div>
								</div>
							</div>
              <div className="accordion-item">
								<h2 className="accordion-header" id="flush-headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="ysxib">
                    Business Details (Optional)
                  </button>
                </h2>
								<div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample" >
									<div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">GST Number</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="gstnumber"
                           value={customerData.gstnumber}
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">PAN Number</label>
                          <input type="text" class="form-control" 
                          
                          autoComplete="off"
                          onChange={handleChange}
                          name="pannumber"
                          value={customerData.pannumber}
                         id="bsValidation1"
                        />
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">CIN Number</label>
                          <input type="text" className="form-control"
                            autoComplete="off"
                            onChange={handleChange}
                            name="cinnumber"
                            value={customerData.cinnumber}
                           id="bsValidation1"
                          
                         />
                          <div className="valid-feedback"></div>
                        </div>
                      </div>
                  </div>
								</div>
							</div>
						</div>
          </form>
        </div>
        <div className="modal-footer d-block">
        <CancelButton
                              buttonText="Cancel"
                              buttonIcon="bx bx-x"
                              onClick={handleClose}
                              additionalClasses="btn-outline-secondary text-left"
                            />
        {updateload ? (
                <ButtonWithProgress
                  label="Updating...."
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary text-right"
               
                />
              ) : (
                <AddButton
                  label="Update"
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary text-right"
                  onClick={handleEdit}
                />
              )}
        </div>
      </div>
    </div>
  </div></div>
  );
};



export default EditCutomerModel;
