import React from "react";


import ListProductTable from "../../reusable/tables/ListProductTable";

function ListProducts() {



  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">List Products</div>
            
          </div>
        
                  <ListProductTable />
               
        </div>
      </div>
    </div>
  );
}

export default ListProducts;
