
import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get('negobill_accessToken');
};

export const getRefreshToken = () => {
  return Cookies.get('negobill_refreshToken');
};

export const setAccessToken = (token) => {
  Cookies.set('negobill_accessToken', token, {
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};

export const setRefreshToken = (token) => {
  Cookies.set('negobill_refreshToken', token, {
    expires: 300,
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};

export const removeAccessToken = () => {
  Cookies.remove('negobill_accessToken', { path: '/' });
};

export const removeRefreshToken = () => {
  Cookies.remove('negobill_refreshToken', { path: '/' });
};















export const setStoreEmail = (email) => {
  Cookies.set('store_email', email, {
    expires: 365, 
    secure: true,  
    sameSite: 'Strict',  
    path: '/'
  });
};





export const getStoreEmail = () => {
  return Cookies.get('store_email');
};
export const removeStoreEmail = () => {
  Cookies.remove('store_email', { path: '/' });
};

export const setStoreTitle = (title) => {
  Cookies.set('store_title', title, {
    expires: 365,
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};


export const getStoreTitle = () => {
  return Cookies.get('store_title');
};


export const removeStoreTitle = () => {
  Cookies.remove('store_title', { path: '/' });
};


export const setStoreId = (id) => {
  Cookies.set('store_id', id, {
    expires: 365,
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};


export const getStoreId = () => {
  return Cookies.get('store_id');
};


export const removeStoreId = () => {
  Cookies.remove('store_id', { path: '/' });
};


// profile access and refresh

export const getAccessStoreToken = () => {
  return Cookies.get('negobill_accessStoreToken');
};

export const getRefreshStoreToken = () => {
  return Cookies.get('negobill_refreshStoreToken');
};

export const setAccessStoreToken = (storeToken) => {
  Cookies.set('negobill_accessStoreToken', storeToken, {
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};

export const setRefreshStoreToken = (storeToken) => {
  Cookies.set('negobill_refreshStoreToken', storeToken, {
    expires: 300,
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};

export const removeAccessStoreToken = () => {
  Cookies.remove('negobill_accessStoreToken', { path: '/' });
};

export const removeRefreshStoreToken = () => {
  Cookies.remove('negobill_refreshStoreToken', { path: '/' });
};



