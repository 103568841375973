import { Skeleton } from '@mui/material';
import React from 'react'


function AddProductTable( {isData,isloading}) {


 
  return (
    <div>
    <div className="table-responsive">
      <table className="table align-middle mb-0">
        <thead className="table-light text-center">
          <tr>
            <th>S.No</th>
            <th>Barcode</th>
            <th>Title</th>
            <th>Sale Price</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
        {isloading ? (
                      [...Array(5)].map((_, index) => (
                        <tr key={index}>
                        
                          <td className="text-center">
                            <Skeleton variant="text" width={200} />
                          </td>
                         
                          <td className="text-center">
                            <Skeleton variant="center" width={200} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                        </tr>
                      ))
                    ) :
                    Array.isArray(isData) && isData.length > 0 ? (
                      isData.map((item, index) => (
                        <tr key={item.id || index}> 
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                          {item.code ? (
    <span className="badge bg-light-purple">{item.code}</span>
  ) : null}</td> 
                          <td className="text-center">
                          <span className="text-heading fw-medium text-truncate" style={{ textTransform: 'capitalize' }}>
  {item.title || ""} 
</span>

                          </td>
                          <td className="text-center">₹{item.saleprice || "0"}</td>
                          <td className="text-center">
                            <span className="badge bg-light-purple">{item.taxpercentage ? `${item.taxpercentage}%` : "0%"}</span> 
                          </td>
                        </tr>
                      ))
        
        ) : (
        
          <tr>
          <td colSpan="5" className="text-center">
            No data Added
          </td>
        </tr>
      )}
        </tbody>
      </table>
    </div>  
    </div>
  )
}

export default AddProductTable
