
import React, { useEffect, useState } from 'react';
import StatCard from './StatCard';
import FullPageLoader from '../reusable/buttons/PageReload';
import { getAccessStoreToken, getAccessToken, getRefreshStoreToken, getStoreEmail, getStoreId, setAccessStoreToken, setRefreshStoreToken, setStoreEmail, setStoreId } from '../utils/CookieUtiles';



function Dashboard({}) {
  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const authToken=getAccessStoreToken();
  const accessToken=getAccessToken();
  const handleCheckAuth = async () => {
    setLoading(true);
    const url = `${base_apiUrl}/auth-token`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (response.status === 404) {
        console.log("No store found, redirecting to /addprofile.");
        window.location.href = "/login"; 
        return;  
      }
     
      console.log("Profile data success", data);
      
      if (data.results && data.results.email) {
        setStoreEmail(data.results.email);
      }
      if (data.results && data.results.storeId) {
        setStoreId(data.results.storeId);
      }
      
      console.log(getStoreEmail(),getStoreId())
      
      setLoading(false);
      
      
    } catch (error) {
      console.error("Error adding barcode:", error);
      setLoading(false);
    }
  };
  const handleCheckProfile = async () => {
    setLoading(true);
    const url = `${base_apiUrl}/auth-store`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (response.status === 404) {
        console.log("No store found, redirecting to /addprofile.");
        window.location.href = "/addprofile"; 
        return;  
      }
     
      console.log("Profile data success", data);
      if (response.ok && data.results && data.results.accessToken) {
        setAccessStoreToken(data.results.accessToken);
        setRefreshStoreToken(data.results.refreshToken);
        console.log("AccessStoreToken", getAccessStoreToken());
        console.log("RefreshStoreToken", getRefreshStoreToken());
        
       
      }
  
      
    } catch (error) {
      console.error("Error adding barcode:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCheckProfile();
    handleCheckAuth();
  }, []);

  return (
          <div>

<div className="page-wrapper">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-lg-4">
            <StatCard
              title="Sessions"
              value="32,842"
              percentage="+40%"
              description="Analytics for last week"
              icon="bx bx-refresh"
              iconClass="bg-gradient-cosmic"
            />
            <StatCard
              title="Users"
              value="16,352"
              percentage="+22%"
              description="Analytics for last week"
              icon="bx bx-group"
              iconClass="bg-gradient-burning"
            />
            <StatCard
              title="Time on Site"
              value="34m 14s"
              percentage="+55%"
              description="Analytics for last week"
              icon="bx bx-time"
              iconClass="bg-gradient-lush"
            />
            <StatCard
              title="Goal Completions"
              value="1,94,2335"
              description="Analytics for last month"
              icon="bx bxs-cube"
              iconClass="bg-gradient-kyoto"
            />
          </div>
        </div>
      </div>
     
   
      
    </div>
  );
}

export default Dashboard;

