// src/reusable/DropdownMenu.js

import React from 'react';

function ExportDropdownMenu({ buttonText, buttonIcon, options, className }) {
  return (
    <div className={`dropdown ${className}`}>
      <button
        className="btn btn-dark dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {buttonIcon && <i className={`bx ${buttonIcon}`}></i>} {buttonText}
      </button>
      <ul className="dropdown-menu">
        {options.map((option, index) => (
          <li key={index}>
            <button className="dropdown-item" onClick={option.onClick}>
              {option.icon && <i className={`bx ${option.icon}`}></i>} <span className="icon-text">{option.text}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ExportDropdownMenu;
