import { useNavigate } from 'react-router-dom';
import { createToken, viewstore } from '../utils/CreateApi';



const useErrorHandle = () => {
  const navigate = useNavigate();

  const handleError = async (response) => {
    if (response.status === 401   ) {
      try {
        const tokenCreated = await createToken(navigate); 
        if (!tokenCreated) {
          console.log("no refresh token")
          navigate('/login');
        }
      } catch (error) {
        console.error('Failed to create token:', error);
    
      }
    }
    else if (response.status === 404) {
      try {
        const viewstore = await viewstore(navigate); 
      
      console.log("404")
     
      navigate('/login');
    } catch (error) {
      console.error('Failed to create token:', error);
  
    }
    }  else if (response.status === 406) {
   
      console.error("Resource not found.");
    } else if (response.status >= 500) {
      console.error("Server error. Please try again later.");
    } else {
      console.error("An unknown error occurred.");
    }
  };

  return handleError;
};

export default useErrorHandle;
