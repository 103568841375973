import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../layout/sidemenu.css'
function SideMenu({ toggleSideMenu }) {
  const [expandedMenu, setExpandedMenu] = useState(null);

  const handleToggleMenu = (menuId) => {
    setExpandedMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
  };

  const handleLinkClick = () => {
    toggleSideMenu();
  };

  return (<>
<div class="wrapper toggled">
   <div className="overlay toggle-icon bc" onClick={toggleSideMenu}></div>
    <div>
      <div className="sidebar-wrapper" data-simplebar="true">
    
        <div className="sidebar-header">
          <div>
            <img
              src="/assets/images/v1/logo-dark.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div className="toggle-icon ms-auto" onClick={toggleSideMenu}>
            <i className="bx bx-arrow-back"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <Link to="/dashboard" onClick={handleLinkClick}>
              <div className="parent-icon" style={{fontSize:'18px'}}>
                {/* <i className="bx bx-home"></i> */}
                <i className="ng-ng-home-icon"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>

          <li>
            <Link to="/customers" onClick={handleLinkClick}>
              <div className="parent-icon" style={{fontSize:'18px'}}>
                <i className="ng-ng-customers-icon"></i>
              </div>
              <div className="menu-title">Customers</div>
            </Link>
          </li>

          <li>
            <div onClick={() => handleToggleMenu("products")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "products" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "products"}
              >
                <div className="parent-icon" style={{fontSize:'18px'}}>
                  <i className="ng-ng-product-icon"></i>
                </div>
                <div className="menu-title">Products</div>
              </Link>
            </div>
            {expandedMenu === "products" && (
              <ul>
                <li>
                  <Link to="/products" onClick={handleLinkClick}>
                    <i className="ng-ng-product-add-icon"></i>Add Products
                  </Link>
                </li>
                <li>
                  <Link to="/listproducts" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>List Products
                  </Link>
                </li>
                <li>
                  <Link to="/stocks" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Manage Stocks
                  </Link>
                </li>
              </ul>
            )}
          </li>
{/* 
          <li>
            <div onClick={() => handleToggleMenu("estimations")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "estimations" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "estimations"}
              >
                <div className="parent-icon">
                  <i className="bx bx-spreadsheet"></i>
                </div>
                <div className="menu-title">Estimations</div>
              </Link>
            </div>
            {expandedMenu === "estimations" && (
              <ul>
                <li>
                  <Link to="/addsales" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Add Sales
                  </Link>
                </li>
                <li>
                  <Link to="/list-sale" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>List Sales
                  </Link>
                </li>
                <li>
                  <Link to="/sales-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Sale Report
                  </Link>
                </li>
                <li>
                  <Link to="/product-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Product Report
                  </Link>
                </li>
              </ul>
            )}
          </li> */}

          <li>
            <div onClick={() => handleToggleMenu("sales")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "sales" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "sales"}
              >
                <div className="parent-icon">
                  <i className="ng-ng-negobill_icon"></i>
                </div>
                <div className="menu-title">Sales</div>
              </Link>
            </div>
            {expandedMenu === "sales" && (
              <ul>
                <li>
                  <Link to="/sales" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Add Sales
                  </Link>
                </li>
                <li>
                  <Link to="/list-sale" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>List Sales
                  </Link>
                </li>
                <li>
                  <Link to="/sales-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Sale Report
                  </Link>
                </li>
                <li>
                  <Link to="/product-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Product Report
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* <li>
            <div onClick={() => handleToggleMenu("purchases")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "purchases" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "purchases"}
              >
                <div className="parent-icon">
                  <i className="bx bx-purchase-tag-alt"></i>
                </div>
                <div className="menu-title">Purchases</div>
              </Link>
            </div>
            {expandedMenu === "purchases" && (
              <ul>
                <li>
                  <Link to="/add-cart" onClick={handleLinkClick}>
                    <i
                      className="bx bx-radio-circle"
                      onClick={handleLinkClick}
                    ></i>
                    Add Sale
                  </Link>
                </li>
                <li>
                  <Link to="/list-sale" onClick={handleLinkClick}>
                    <i
                      className="bx bx-radio-circle"
                      onClick={handleLinkClick}
                    ></i>
                    List Sales
                  </Link>
                </li>
                <li>
                  <Link to="/sales-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Sale Report
                  </Link>
                </li>
                <li>
                  <Link to="/product-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Product Report
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <div onClick={() => handleToggleMenu("returns")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "returns" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "returns"}
              >
                <div className="parent-icon">
                  <i className="bx bx-redo"></i>
                </div>
                <div className="menu-title">Return Sales</div>
              </Link>
            </div>
            {expandedMenu === "returns" && (
              <ul>
                <li>
                  <Link to="/return-cart" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Return Sale
                  </Link>
                </li>
                <li>
                  <Link to="/list-return" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>List Return
                  </Link>
                </li>
              </ul>
            )}
          </li>  */}

          <li>
            <div onClick={() => handleToggleMenu("expenses")}>
              <Link
                to="#"
                className={`has-arrow ${
                  expandedMenu === "expenses" ? "mm-active" : ""
                }`}
                aria-expanded={expandedMenu === "expenses"}
              >
                <div className="parent-icon">
                  <i className="ng-ng-expence-icon"></i>
                </div>
                <div className="menu-title">Expenses</div>
              </Link>
            </div>
            {expandedMenu === "expenses" && (
              <ul>
                <li>
                  <Link to="/expenses" onClick={handleLinkClick}>
                    <i className="ng-ng-expence-add-icon"></i>Add Expense
                  </Link>
                </li>
                <li>
                  <Link to="/expense-report" onClick={handleLinkClick}>
                    <i className="bx bx-radio-circle"></i>Expense Report
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {/* <li>
            <Link to="/boxcash" onClick={handleLinkClick}>
              <div className="parent-icon" style={{fontSize:'15px'}}>
                <i className="ng-ng-card-swipe-icon"></i>
              </div>
              <div className="menu-title">Box Cash</div>
            </Link>
          </li> */}

    {/*     

          <li>
            <Link to="/paylater" onClick={handleLinkClick}>
              <div className="parent-icon">
                <i className="bx bx-hourglass"></i>
              </div>
              <div className="menu-title">Pay Later</div>
            </Link>
          </li> */}







        </ul>
      </div>
    </div>
    </div>
    </>
  );
}

export default SideMenu;

