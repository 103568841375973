import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AddButton from '../reusable/AddButton';
import CancelButton from '../reusable/CancelButton';
import StateDropdown from '../reusable/StateDropDown';
import ButtonWithProgress from '../reusable/ButtonWithProgress';


const CustomerModal = ({ isOpen, onClose, onAdd ,buttonloading}) => {

  const [errors, setErrors] = useState({});
  const customerNameRef = useRef(null);
  const customerPhoneRef = useRef(null);
  const customerEmailRef=useRef(null);
  const [customerData, setCustomer] = useState({
    name: "",
    phone: "",
    email: "",

    addressline1: "",
    addressline2:"",
    state: "",
    city: "",

    district: "",
    country: "",
    pincode: "",
    cinnumber: "",
    gstnumber: "",
    pannumber: "",
  });


  const customerGSTRef = useRef(null);
  const customerPanRef = useRef(null);
  const customerCinRef = useRef(null);
  const customerAddressOneRef = useRef(null);
  const customerAddressTwoRef = useRef(null);
  const customerCityRef = useRef(null);

  const customerDistrictRef = useRef(null);
  const customerStateRef = useRef(null);
  const customerCountryRef = useRef(null);
  const customerPincodeRef = useRef(null);

  const handleKeyDown = (e, fieldRef, fieldName) => {
    setErrors("");
  
    if (e.key === "Enter") {
      e.preventDefault();
  
      const fields = [
        { name: "name", ref: customerNameRef, required: true },
        { name: "phone", ref: customerPhoneRef, required: true },
        { name: "addressline1", ref: customerAddressOneRef, required: false },
        { name: "addressline2", ref: customerAddressTwoRef, required: false },
        { name: "city", ref: customerCityRef, required: false },
        { name: "district", ref: customerDistrictRef, required: false },
        // { name: "state", ref: customerStateRef, required: false },
        { name: "country", ref: customerCountryRef, required: false },
        { name: "pincode", ref: customerPincodeRef, required: false },
        { name: "email", ref: customerEmailRef, required: false },
        { name: "gstnumber", ref: customerGSTRef, required: false },
        { name: "pannumber", ref: customerPanRef, required: false },
        { name: "cinnumber", ref: customerCinRef, required: false },
      ];
  
      const currentIndex = fields.findIndex(field => field.name === fieldName);
  
    
      if (fieldName === "cinnumber") {
        handleAdd();
        return;
      }
  
  
      if (fields[currentIndex].required && !customerData[fieldName]?.trim()) {
      
        fieldRef?.current?.focus();
        return;
      }
  
      
      const nextField = fields[currentIndex + 1];
      if (nextField) {
        nextField.ref?.current?.focus();
      }
    }
  };
  
  useEffect(() => {
    customerNameRef.current && customerNameRef.current.focus();
  
  }, []);
  if (!isOpen) return null;
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue;
    if (
      name === "gstnumber" ||
      name === "pannumber" ||
      name === "cinnumber" ||
     
      name === "email" ||
      name === "pincode"
    ) {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }

    setCustomer((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));

    
  };
  const stopEventPropagation = (e) => {
    e.stopPropagation();  
  };





  const resetForm = () => {
    setCustomer({
      name: "",
      phone: "",
      email: "",
      addressline1: "",
      addressline2: "",
      state: "",
      city: "",
      district: "",
      country: "",
      pincode: "",
      cinnumber: "",
      gstnumber: "",
      pannumber: "",
    });
    setErrors({});
  };
  



  const handleClose = () => {
    resetForm();
    onClose();
    
  };


  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!customerData.name) {
      newErrors.name = "This field must not be empty.";
      
      valid = false;
      customerNameRef.current.focus();
    }
   
     else if   (!customerData.phone) {
      newErrors.phone = "This field must not be empty.";
      valid = false;
      customerPhoneRef.current.focus();
    } else if (customerData.phone.length !== 10) {
      newErrors.phone = "Phone Number must be 10 digits.";
      customerPhoneRef.current.focus();
      valid = false;
    } else if (!/^\d{10}$/.test(customerData.phone)) {
      newErrors.phone = "Phone Number must be numeric.";
      customerPhoneRef.current.focus();
      valid = false;
    }else if (customerData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerData.email)) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
      customerEmailRef.current.focus();
    }
    
    if (customerData.gstnumber && customerData.gstnumber.length > 1000) {
      newErrors.gstnumber = "GST Number must not exceed 1000 characters.";
      valid = false;
    }
    else if (customerData.addressline1 && customerData.addressline1.length > 2000) {
      newErrors.addressline1 =
        "Address Line 1 must not exceed 2000 characters.";
      valid = false;
    } else if (customerData.addressline2 && customerData.addressline2.length > 2000) {
      newErrors.addressline2 =
        "Address Line 2 must not exceed 2000 characters.";
      valid = false;
    }else if (customerData.pincode && customerData.pincode.length < 6) {
      newErrors.pincode = "Pincode must be at least 6 characters long.";
      valid = false;
    }
      else if (customerData.pincode && isNaN(customerData.pincode)) {
        newErrors.pincode = "Pincode must be a valid number.";
        valid = false;
      }
  

    setErrors(newErrors);
    return valid;
  };

  const handleAdd = () => {
   
    if (!validateFields()) {
      return;
    }
    setErrors("");
   
    onAdd(customerData);
    resetForm(); 
  };



  return (
    <div>
  <div className="modal-backdrop show" onClick={handleClose}  ></div>
  <div className="modal show" id="CustomerModal"tabIndex="-1" aria-modal="true" role="dialog" onClick={handleClose} style={{display: "block"}}>
  
    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" onClick={stopEventPropagation}>
      <div className="modal-content">
        <div className="modal-header">
          <h6 className="modal-title">Add Customer</h6>
          <button type="button" className="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close" fdprocessedid="jgxcje"></button>
        </div>
        <div className="modal-body bg-light">
          <form>
            <div className="card">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <label htmlFor="FisrtName" className="form-label">Name 
                      <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" name="name"
                      autoComplete="off"
                      value={customerData.name}
                    
                      onChange={handleChange}
                      ref={customerNameRef}
                      onKeyDown={(e) =>
                        handleKeyDown(e, customerNameRef, "name")
                      }
                      
                      
                      />
                       
                       {errors.name && (
                      <div className="error-message invalid-feedback" > 
                        {errors.name}
                     </div>
                    )} 
                  </div>
                  
                  <div className="col-lg-6">
                    <label htmlFor="phone" className="form-label">Phone <span className="text-danger">*</span></label>
                    <div className="input-group">
                      <span className="input-group-text" >+91</span>
                      <input type="text" className="form-control"
                       autoComplete="off"
                       name="phone"
                       ref={customerPhoneRef}
                       onKeyDown={(e) =>
                        handleKeyDown(e, customerPhoneRef, "phone")
                      }
                       value={customerData.phone}
                       onChange={(e) => {
                         const value = e.target.value;

                         if (/^\d{0,10}$/.test(value)) {
                           handleChange(e);
                         }
                       }}/>

                    
                    </div>
                    {errors.phone && (
                      <div className="error-message invalid-feedback" > 
                        {errors.phone}
                     </div>
                    )} 
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion accordion-flush">
							<div className="accordion-item">
								<h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="5b1j1a">
                    Billing Details (Optional)
                  </button>
                </h2>
								<div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Address Line 1</label>
                          <textarea type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="addressline1"
                           ref={customerAddressOneRef}
                           onKeyDown={(e) =>
                            handleKeyDown(e, customerAddressOneRef, "addressline1")
                          }
                           value={customerData.addressline1}
                          id="bsValidation1"></textarea>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Address Line 2</label>
                          <textarea type="text" className="form-control"
                           autoComplete="off"
                           ref={customerAddressTwoRef}
                           onChange={handleChange}
                           onKeyDown={(e) =>
                            handleKeyDown(e, customerAddressTwoRef, "addressline2")
                          }
                           name="addressline2"
                           value={customerData.addressline2}
                          id="bsValidation1"></textarea>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">City</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           ref={customerCityRef}
                           onKeyDown={(e) =>
                            handleKeyDown(e, customerCityRef, "city")
                          }
                           name="city"
                           value={customerData.city}
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">District</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="district"
                           ref={customerDistrictRef}
                           value={customerData.district}
                           onKeyDown={(e) =>
                            handleKeyDown(e, customerDistrictRef, "district")
                          }
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                                  
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">State</label>

                          <StateDropdown  stateChange={handleChange} 
                          value={customerData.state}   />
                            
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-3">
                          <label htmlFor="bsValidation1" className="form-label">Country</label>
                          <input type="text" disabled="" className="form-control"
                          
                          autoComplete="off"
                          onChange={handleChange}
                          name="country"
                          ref={customerCountryRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, customerCountryRef, "country")
                          }
                          value={customerData.country} id="bsValidation1"/> 
                          <div className="valid-feedback"></div>
                        </div>
                        
           
                        <div className="col-lg-3">
                          <label htmlFor="bsValidation1" className="form-label">Pincode</label>
                          <input type="text"
                                maxLength={6}
                                className="form-control"
                                autoComplete="off"
                                onChange={handleChange}
                                name="pincode"
                                ref={customerPincodeRef}
                                onKeyDown={(e) =>
                                  handleKeyDown(e, customerPincodeRef, "pincode")
                                }
                                value={customerData.pincode}/>
                                  {errors.pincode && (
                                <div className="error-message invalid-feedback">
                                  {errors.pincode}
                                </div>
                              )}
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bsValidation1" className="form-label">Email</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="email"
                           ref={customerEmailRef}
                           onKeyDown={(e) =>
                            handleKeyDown(e, customerEmailRef, "email")
                          }
                           value={customerData.email}
                    
                          id="bsValidation1"/>


{errors.email && (
                                <div className="error-message invalid-feedback">
                                  {errors.email}
                                </div>
                              )}
                          <div className="valid-feedback"></div>
                        </div>
                      </div>
                  </div>
								</div>
							</div>
              <div className="accordion-item">
								<h2 className="accordion-header" id="flush-headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne" fdprocessedid="ysxib">
                    Business Details (Optional)
                  </button>
                </h2>
								<div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample" >
									<div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">GST Number</label>
                          <input type="text" className="form-control"
                           autoComplete="off"
                           onChange={handleChange}
                           name="gstnumber"
                           ref={customerGSTRef}
                           onKeyDown={(e) =>
                             handleKeyDown(e, customerGSTRef, "gstnumber")
                           }
                           value={customerData.gstnumber}
                          id="bsValidation1"/>
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">PAN Number</label>
                          <input type="text" class="form-control" 
                          
                          autoComplete="off"
                          onChange={handleChange}
                          name="pannumber"
                          ref={customerPanRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, customerPanRef, "pannumber")
                          }
                          value={customerData.pannumber}
                         id="bsValidation1"
                        />
                          <div className="valid-feedback"></div>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="bsValidation1" className="form-label">CIN Number</label>
                          <input type="text" className="form-control"
                            autoComplete="off"
                            onChange={handleChange}
                            name="cinnumber"
                            ref={customerCinRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, customerCinRef, "cinnumber")
                          }
                            value={customerData.cinnumber}
                           id="bsValidation1"
                          
                         />
                          <div className="valid-feedback"></div>
                        </div>
                      </div>
                  </div>
								</div>
							</div>
						</div>
          </form>
        </div>
        <div className="modal-footer d-block">
        <CancelButton
                              buttonText="Cancel"
                              buttonIcon="bx bx-x"
                              onClick={handleClose}
                              additionalClasses="btn-outline-secondary text-left"
                            />
        {buttonloading ? (
                <ButtonWithProgress
                  label="Adding...."
                  iconClass="ng-ng-add-icon"
                  buttonClass="btn btn-primary text-right"
                  onClick={handleAdd}
                />
              ) : (
                <AddButton
                  label="Add"
                  iconClass="ng-ng-add-icon"
                  buttonClass="btn btn-primary text-right"
                  onClick={handleAdd}
                />
              )}
        </div>
      </div>
    </div>
  </div></div>
  );
};

CustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default CustomerModal;
