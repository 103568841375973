
import React, { useEffect, useState } from "react";

import CustomButtonAdd from "../reusable/CusstomButtonAdd";
import ExportDropdownMenu from "../reusable/ExportDropdownMenu";
import ExprnseTableData from "../reusable/tables/ExpenseTableData";

import AddExpenseDialog from "../menuList/expenseData/AddExpenseDialog";

import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import useErrorHandle from "../error/ErrorHandling";
import DatePickerButton from "../reusable/salesButton/DatePickerButton";
import AddNegoButton from "../reusable/AddNegoButton";
import { getStoreId } from "../utils/CookieUtiles";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Expense({authToken}) {
  const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [buttonloading, setButtonLoading] = useState(false);
  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleError = useErrorHandle();
  const storeid=getStoreId();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
console.log("express",authToken)
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleAdd = async (newCustomer) => {
    setButtonLoading(true);
    const url = `${base_apiUrl}/add-expense`;

    const payload = {
      storeid: storeid,
      title: newCustomer.title,
      expensedate: newCustomer.expensedate,
      paymode: newCustomer.paymode,
      amount: newCustomer.amount,
      description: newCustomer.description,

  
    };


    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      const data = await response.json();
      if (!response.ok) {
        setSnackbarSeverity("error");

        setSnackbarMessage(data.message || "An error occurred");
        await handleError(response);

        setButtonLoading(false);
        return;
      }
      setData((prevShops) => {
        
        return [...(prevShops || []), data.results];
      });

      setSnackbarSeverity("success");
      setSnackbarMessage("Added successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error adding shop:", error);
      setSnackbarSeverity("An error occurred. Please try again.");

      setButtonLoading(false);
    }
  };

  const handleCloseIsModalOpen = () => {
    setModalOpen(false);
  };


  const handleSnackbarClose = ( reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const options = [
    {
      icon: "bx-printer",
      text: "Print",
      onClick: () => console.log("Print clicked"),
    },
    {
      icon: "bx-spreadsheet",
      text: "Excel",
      onClick: () => console.log("Export to Excel clicked"),
    },
    {
      icon: "bx-receipt",
      text: "PDF",
      onClick: () => console.log("Export to PDF clicked"),
    },
  ];

  const fetchExpenseData = async () => {
    setLoading(true);
    const url = `${base_apiUrl}/list-expense`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        storeid: storeid,
        expensedate: inputValue
      })
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setData(data.results);
      console.log("data.results", data.results);
    } catch (error) {
      console.error("Error fetching Added list product:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchExpenseData();
  }, [inputValue]);



  const onDelete = async (ExpenseId) => {
    console.log("deleteid",ExpenseId)
    const url = `${base_apiUrl}/delete-expense`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({storeid: storeid, expenseid : ExpenseId }),
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
        return;
      }
  
 
      setData((prevCustomerData) =>
        prevCustomerData.filter((customer) => customer.ExpenseId !== ExpenseId)
      );
      fetchExpenseData();
      setSnackbarSeverity("success");
      setSnackbarMessage("Deleted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting product:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error deleting product.");
      setSnackbarOpen(true);
    }
  };






  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-flex align-items-center">
          <div className="breadcrumb-title pe-3 py-2">Expenses</div>
          <div className="ms-auto">
            <ExportDropdownMenu
              buttonText="Export"
              buttonIcon="bx-export"
              options={options}
              className="my-custom-class"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card radius-10 mb-0 mt-3">
              <div className="card-widget-separator-wrapper">
                <div className="card-body px-0 py-0 mb-3">
                  <div className="card-header justify-content-between align-items-center px-3 py-3">
                    <div className="d-sm-flex align-items-center gap-3">
                      <div className="position-relative">
                        <DatePickerButton
                          icon="bx bx-calendar"
                          inputType="text"
                          inputValue={inputValue}
                          className="createdon"
                          onInputChange={(e) => setInputValue(e.target.value)}
                          inputPlaceholder="Enter Date"
                        />
                      </div>
                      <div className="ms-auto">
                        <AddNegoButton
                          text="Add Expense"
                          icon="ng-ng-expence-add-icon"
                          onClick={handleOpenModal}
                          className="btn-primary mt-2 mt-lg-0"
                        />
                      </div>
                    </div>
                  </div>
                  <ExprnseTableData  onDelete={onDelete} isloading={isloading} data={data} setData={setData} />

              
                  {isModalOpen && (<>
                          <div className="modal-backdrop show" onClick={handleCloseIsModalOpen}  ></div>
                          <AddExpenseDialog handleCloseModal={handleCloseModal}  onAdd={handleAdd }buttonloading={buttonloading}  />
                  
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
    </div>
  );
}

export default Expense;
