import React, { useRef, useState } from "react";
import CancelButton from "../../reusable/CancelButton";
import AddButton from "../../reusable/AddButton";
import ButtonWithProgress from "../../reusable/ButtonWithProgress";
import AddNegoButton from "../../reusable/AddNegoButton";
import DatePickerButton from "../../reusable/salesButton/DatePickerButton";

function AddExpenseDialog({ handleCloseModal, onAdd, buttonloading }) {
  const [errors, setErrors] = useState({});
  const TitleRef = useRef(null);
  const AmountRef = useRef(null);
  const ExpenseDateRef = useRef(null);
  const PayModeRef = useRef(null);
  const [expenseData, setExpense] = useState({
    title: "",
    expensedate: "",
    paymode: "",

    amount: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue;

    if (name === "expensedate" || name === "paymode" || name === "amount") {
      trimmedValue = value.replace(/\s/g, "");

      if (name === "paymode") {
        trimmedValue = trimmedValue.toUpperCase();
      }
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }

    setExpense((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
  };

  const handleClose = () => {
    resetForm();
    handleCloseModal();
  };

  const resetForm = () => {
    setExpense({
      title: "",
      expensedate: "",
      paymode: "",

      amount: "",
      description: "",
    });
    setErrors({});
  };
  const validateDate = (date) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return datePattern.test(date);
  };
  const stopEventPropagation = (e) => {
    e.stopPropagation();
  };
  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!expenseData.title) {
      newErrors.title = "This field must not be empty.";

      valid = false;
      TitleRef.current.focus();
    } else if (!expenseData.expensedate) {
      newErrors.expensedate = "This field must not be empty.";

      valid = false;
      ExpenseDateRef.current.focus();
    } else if (!validateDate(expenseData.expensedate)) {
      newErrors.expensedate =
        "Invalid date format. Provide a valid date (yyyy-mm-dd).";
      valid = false;
      ExpenseDateRef.current.focus();
    } else if (!expenseData.paymode) {
      newErrors.paymode = "This field must not be empty.";

      valid = false;
      PayModeRef.current.focus();
    } else if (!expenseData.amount) {
      newErrors.amount = "This field must not be empty.";

      valid = false;
      AmountRef.current.focus();
    }

    setErrors(newErrors);
    return valid;
  };

  const handleAdd = () => {
    if (!validateFields()) {
      return;
    }
    setErrors("");

    onAdd(expenseData);
    resetForm();
  };
  return (
    <div>
      <div
        className="modal fade show"
        id="ExpenseModal"
        tabIndex="-1"
        aria-labelledby="ExpenseModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
        onClick={handleClose}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          onClick={stopEventPropagation}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="ExpenseModalLabel">
                Add Expense
              </h6>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body bg-light">
              <form>
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-lg-7">
                        <label htmlFor="title" className="form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          autoComplete="off"
                          value={expenseData.title}
                          onChange={handleChange}
                          ref={TitleRef}
                        />
                        {errors.title && (
                          <div className="error-message invalid-feedback">
                            {errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-5">
                        <label htmlFor="expenseDate" className="form-label">
                          Expense Date <span className="text-danger">*</span>
                        </label>
                        <DatePickerButton
                          icon="bx bx-calendar"
                          inputType="text"
                          inputValue={expenseData.expensedate}
                          className="createdon"
                          onInputChange={(e) => handleChange({ ...e, target: { ...e.target, name: "expensedate" } })}
                          inputPlaceholder="Enter Date"
                        />
                        
                        
                        {errors.expensedate && (
                          <div className="error-message invalid-feedback">
                            {errors.expensedate}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          autoComplete="off"
                          value={expenseData.description}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="payMode" className="form-label">
                          Pay Mode <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="paymode"
                          autoComplete="off"
                          value={expenseData.paymode}
                          onChange={handleChange}
                          ref={PayModeRef}
                          className="form-control"
                          id="payMode"
                        />
                        {errors.paymode && (
                          <div className="error-message invalid-feedback">
                            {errors.paymode}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="amount" className="form-label">
                          Amount <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                          name="amount"
                          autoComplete="off"
                          value={expenseData.amount}
                          onChange={handleChange}
                          ref={AmountRef}
                        />
                        {errors.amount && (
                          <div className="error-message invalid-feedback">
                            {errors.amount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer d-block">
              <CancelButton
                buttonText="Cancel"
                buttonIcon="bx bx-x"
                onClick={handleClose}
                additionalClasses="btn-outline-secondary text-left"
              />
              {buttonloading ? (
                <ButtonWithProgress
                  label="Adding...."
                  iconClass="ng-ng-add-icon"
                  buttonClass="btn btn-primary text-right"
                  onClick={handleAdd}
                />
              ) : (<>
                {/* <AddButton
                  label="Add"
                  iconClass="bx bx-plus"
                  buttonClass="btn btn-primary text-right"
                  onClick={handleAdd}
                /> */}
                <AddNegoButton
                text="Add "
                icon="ng-ng-add-icon"
                onClick={handleAdd}
                className="btn btn-primary text-right"
              />
              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddExpenseDialog;
