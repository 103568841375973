import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicTitleAndIcon = () => {
  const location = useLocation();

  const updateTitleAndIcon = (pathname) => {
    let title = 'Nego Bill - Dashboard';
    let iconPath = '/assets/images/v1/favicon-32x32.png'; 

    switch (pathname) {
      case '/dashboard':
        title = 'Nego Bill - Dashboard';
        break;
      case '/sales':
        title = 'Nego Bill - Sales';
        iconPath = '/assets/images/v1/favicon-32x32.png'; 
        break;
      case '/expenses':
        title = 'Nego Bill - Expenses';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
      case '/boxcash':
        title = 'Nego Bill - Box Cash';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
        case '/login':
            title = 'Nego Bill - Login';
            iconPath = '/assets/images/v1/favicon-32x32.png';
            break;
      case '/paylater':
        title = 'Nego Bill - Pay Later';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
      case '/customers':
        title = 'Nego Bill - Customers';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
      case '/products':
        title = 'Nego Bill - Products';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
      case '/listproducts':
        title = 'Nego Bill - List Products';
        iconPath = '/assets/images/v1/favicon-32x32.png';

        break;
      case '/stocks':
        title = 'Nego Bill - Stocks';
        iconPath = '/assets/images/v1/favicon-32x32.png';
        break;
      default:
        title = 'Nego Bill - Dashboard';
    }

    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = iconPath;
    }

    document.title = title;
  };

  useEffect(() => {
    updateTitleAndIcon(location.pathname);
  }, [location.pathname]);

  return null; 
};

export default DynamicTitleAndIcon;
