// DataTable.js
import React from 'react';
import EditButton from '../EditIcon';
import DeleteButton from '../DeleteIcon';


function SalesTable({ tableData, handleEdit, handleDelete }) {
  return (
    <div className="table-responsive mt-0">
      <table className="table table-bordered align-middle mb-0">
        <thead className="table-light">
          <tr>
            <th className="text-center">S.No</th>
            <th>Description</th>
            <th className="text-center">MRP</th>
            <th className="text-center">Discount</th>
            <th className="text-center">Sale Price</th>
            <th className="text-center">Qty</th>
            <th className="text-center">Total</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                <div className="d-flex flex-column w-200 text-wrapper">
                  <span className="text-left text-heading fw-medium">
                    {item.name}
                  </span>
                </div>
              </td>
              <td className="text-center mx-100">{item.price}</td>
              <td className="text-center mx-100">{item.amount}</td>
              <td className="text-center mx-100">{item.total}</td>
              <td className="text-center mx-100">
                <input
                  type="text"
                  className="form-control invoice-item-qty text-center w-100"
                  value={item.quantity}
                  max="40"
                />
              </td>
              <td className="text-center mx-100">
                <span className="text-heading fw-medium text-truncate">
                  {item.totalAmount}
                </span>
              </td>
              <td className="text-center">
                <div className="order-actions">
                  <EditButton onEdit={() => handleEdit(item.id)} />
                  <DeleteButton onDelete={() => handleDelete(item.id)} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SalesTable;
