import React from 'react';

function StatCard({ title, value, percentage, percentageText, description, icon, iconClass }) {
  return (
    <div className="col">
      <div className="card radius-10">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="mb-0">{title}</p>
              <h4 className="font-weight-bold">
                {value}{' '}
                {percentage && <small className="text-success font-13">({percentage})</small>}
              </h4>
              <p className={`mb-0 font-13 ${percentageText ? 'text-success' : 'text-secondary'}`}>
                {description}
              </p>
            </div>
            <div className={`widgets-icons ${iconClass} text-white`}>
              <i className={icon}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
