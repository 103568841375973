// blue theme add with plus
import React from 'react';

function AddNegoButton({ text, icon,  className , onClick }) {
  return (
    <button
      type="button"
      className={`btn ${className}`}
      
      
      onClick={onClick}
    >
      {icon && <i className={` ${icon}`}></i>} {text}
    </button>
  );
}

export default AddNegoButton;
