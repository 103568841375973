import React, { useState } from 'react';
import ExportDropdownMenu from '../reusable/ExportDropdownMenu';

import BoxCashTable from '../reusable/tables/BoxCashTable';
import DatePickerButton from '../reusable/salesButton/DatePickerButton';
const data1 = [
    {
      id: 1,
      mode: 'Cash In',
      date: '03 Nov 2023',
      time: '06:15 pm',
      description: 'Air Jordan is a line of basketball shoes produced by Nike',
      amount: '₹899.00',
      badge: 'A1230394',
      badgeColor: 'deep-purple',
    },
    {
      id: 2,
      mode: 'Cash In',
      date: '03 Nov 2023',
      time: '06:15 pm',
      description: 'Air Jordan is a line of basketball shoes produced by Nike',
      amount: '₹899.00',
      badge: 'B0512231',
      badgeColor: 'blue',
    },
    {
      id: 3,
      mode: 'Cash Out',
      date: '03 Nov 2023',
      time: '06:15 pm',
      description: 'Air Jordan is a line of basketball shoes produced by Nike',
      amount: '- ₹899.00',
      badge: 'A5230394',
      badgeColor: 'deep-purple',
    },
  ];

const options = [
    {
      icon: "bx-printer",
      text: "Print",
      onClick: () => console.log("Print clicked"),
    },
    {
      icon: "bx-spreadsheet",
      text: "Excel",
      onClick: () => console.log("Export to Excel clicked"),
    },
    {
      icon: "bx-receipt",
      text: "PDF",
      onClick: () => console.log("Export to PDF clicked"),
    },
  ];
function BoxCash() {
  const[transactionData,setTransaction]=useState(data1)
    const [inputValue, setInputValue] = useState("09 Jan 2024");
  const handleCashOut = () => {
    console.log('Cash Out clicked');
   
  };

  const handleCashIn = () => {
    console.log('Cash In clicked');
    
  };

 

  const handleDelete = (id) => {
    console.log(`Delete action clicked for id: ${id}`);
  
  };
 
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-flex align-items-center">
            <div className="breadcrumb-title pe-3 py-2">Box Cash</div>
            <div className="ms-auto">
            <ExportDropdownMenu
              buttonText="Export"
              buttonIcon="bx-export"
              options={options}
              className="my-custom-class"
            />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card radius-10 mb-0 mt-3">
                <div className="card-widget-separator-wrapper">
                  <div className="card-header justify-content-between align-items-center px-3 py-3">
                    <div className="d-lg-flex align-items-center">
                      <div>
                      <DatePickerButton
                      icon="bx bx-calendar"
                      inputType="text"
                      inputValue={inputValue}
                      className="createdon"
                      onInputChange={(e) => setInputValue(e.target.value)}
                      inputPlaceholder="Enter Date"
                    />
                       
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-lg-3">
                        <div className="d-flex justify-content-between align-items-center card-widget-1 p-2 radius-10 border">
                          <div>
                            <p className="mb-2">Opening Balance</p>
                            <h4 className="mb-0">₹345.00</h4>
                          </div>
                          <div className="avatar me-sm-0">
                            <span className="avatar-initial rounded bg-label-light">
                              <i className="bx bx-coin"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex text-center justify-content-between align-items-center card-widget-1 radius-10 border bg-label-success">
                          <button className="btn btn-danger box-btn-left" onClick={handleCashOut}>
                            <i className="bx bx-minus"></i> <span className="d-title">Cash Out</span>
                          </button>
                          <div>
                            <p className="mb-2">Cash In Box</p>
                            <h4 className="mb-0 text-success">₹2345.00</h4>
                          </div>
                          <button className="btn btn-success box-btn-right" onClick={handleCashIn}>
                            <i className="bx bx-plus"></i> <span className="d-title">Cash In</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="d-flex justify-content-between align-items-center card-widget-1 p-2 radius-10 border">
                          <div>
                            <p className="mb-2">Closing Balance</p>
                            <h4 className="mb-0 text-secondary">₹5345.00</h4>
                          </div>
                          <div className="avatar me-sm-0">
                            <span className="avatar-initial rounded bg-label-light">
                              <i className="bx bx-coin-stack"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BoxCashTable data={transactionData} handleDelete={handleDelete} />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxCash;
