import React, { useEffect, useRef, useState } from "react";
import CancelButton from "../../reusable/CancelButton";
import AddButton from "../../reusable/AddButton";
import AddProductTable from "../../reusable/tables/AddProductTable";
import ButtonWithProgress from "../../reusable/ButtonWithProgress";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useErrorHandle from "../../error/ErrorHandling";
import { getStoreId } from "../../utils/CookieUtiles";
import Big from 'big.js';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddProducts({ authToken }) {
  const productTitleRef = useRef(null);
  const productsalepriceRef = useRef(null);
  const productCodeRef = useRef(null);
  const productHsnsacRef = useRef(null);
  const productMrpRef = useRef(null);
  const producttaxpercentageeRef = useRef(null);
  const AddButtonRef = useRef(null);
 
  const productbasepriceRef = useRef(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleError = useErrorHandle();
  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const [errors, setError] = useState({});
  const [buttonloading, setButtonLoading] = useState(false);
  const [barcodeloading, setBarLoading] = useState(false);
  const [newbarcode, setNewBarcode] = useState("");
  const [isData, setData] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [isProductData, setProductData] = useState({
    code: "",
    hsnsac: "",
    title: "",
    mrp: "",
    saleprice: "",
    taxpercentage: "0",
    discount: "",
    baseprice: "",
    taxamount: "",
  });
  const storeid=getStoreId();
  const [selectedInput, setSelectedInput] = useState("saleprice");


  const validateFields = () => {
    let valid = true;
    const newErrors = {};
    if (!isProductData.code) {
      newErrors.code = "This field must not be empty. Please enter a value.";
      valid = false;
      productCodeRef.current.focus();
    } else if (!isProductData.title) {
      newErrors.title = "This field must not be empty. Please enter a value.";
      valid = false;
      productTitleRef.current.focus();
    } else if (!isProductData.saleprice) {
      newErrors.saleprice =
        "This field must not be empty. Please enter a value.";
      valid = false;
      productsalepriceRef.current.focus();
    } else if (
      parseFloat(isProductData.saleprice) > parseFloat(isProductData.mrp)
    ) {
      newErrors.saleprice = "Sale price should be less than or equal to MRP.";
      valid = false;
      productsalepriceRef.current.focus();
    }

    setError(newErrors);
    return valid;
  };
  const handleClose = () => {
    setProductData({
      code: "",
      hsnsac: "",
      title: "",
      mrp: "",
      saleprice: "",
      taxpercentage: "",
      discount: "",
      baseprice: "",
      taxamount: "",
    });
  };
  useEffect(() => {
    if (newbarcode) {
      setProductData((prevData) => ({
        ...prevData,
        code: newbarcode,
      }));
    }
  }, [newbarcode]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const addProduct = async (newProduct) => {
    setButtonLoading(true);

    const url = `${base_apiUrl}/add-product`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        storeid: storeid,
        code: newProduct.code,
        mrp: Number(newProduct.mrp),
        saleprice: Number(newProduct.saleprice),
        title: newProduct.title,
        taxpercentage: newProduct.taxpercentage,
        discount: newProduct.discount,
        baseprice: newProduct.baseprice,
        taxamount: newProduct.taxamount,
        hsnsac: newProduct.hsnsac,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (!response.ok) {
        await handleError(response);

        setButtonLoading(false);
        return;
      }

      setData((prevProducts) => [...prevProducts, data.results]);
      fetchRecentProduct();
      setSnackbarSeverity("success");
      setSnackbarMessage("Product added successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false);
      handleClose();
    } catch (error) {
      console.error("Error adding product:", error);
      setButtonLoading(false);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error adding product.");
      setSnackbarOpen(true);
    }
  };

  const fetchRecentProduct = async () => {

    setLoading(true);
    const url = `${base_apiUrl}/recent-product`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
      storeid:storeid
      })
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setData(data.results);
      console.log("data.results", data.results);
    } catch (error) {
      console.error("Error fetching Added recent product:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateBarcode = async () => {
    productCodeRef.current?.focus();
    setBarLoading(true);

    const url = `https://api.negobill.com/v2/barcode`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        await handleError(response);

        setButtonLoading(false);
        return;
      }
      const newBarcode = data.result.barcode;
      console.log("newBarcode", newBarcode);
      setNewBarcode(newBarcode);
      setProductData((prevData) => ({
        ...prevData,
        code: newBarcode,
      }));
      console.log("barcode", isProductData.code);

      setBarLoading(false);
    } catch (error) {
      console.error("Error adding barcode:", error);
      setBarLoading(false);
    }
  };

  const handleAdd = () => {
    if (!validateFields()) {
      return;
    }

    addProduct(isProductData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError("");
    let trimmedValue;
    if (
      name === "code" ||
      name === "mrp" ||
      name === "saleprice" ||
      name === "discount" ||
      name === "baseprice" ||
      name === "taxamount"
    ) {
      trimmedValue = value.replace(/[a-zA-Z]/g, "").replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }

    setProductData((prevData) => {
      const updatedData = { ...prevData, [name]: trimmedValue };

      return updatedData;
    });
  };


  const handleMrpChange = (e) => {
    let newMrp = e.target.value;
    newMrp = newMrp.replace(/[a-zA-Z]/g, "");

    setProductData((prevData) => {
      const updatedData = {
        ...prevData,
        mrp: newMrp,
      };

      return updatedData;
    });
  };

  const handleBlur = () => {
    setProductData((prevData) => {
      let newSalePrice = prevData.saleprice || prevData.mrp;
      let newMrp = prevData.mrp || prevData.saleprice;

      if (parseFloat(newSalePrice) > parseFloat(newMrp)) {
        newMrp = newSalePrice;
      }

      return {
        ...prevData,
        saleprice: newSalePrice,
        mrp: newMrp,
      };
    });
    console.log("Updated Sale Price and MRP with Discount.");
  };


  
  useEffect(() => {
    const { mrp, saleprice,  } = isProductData;
  
   
    const newDiscount = calculateDiscount(mrp, saleprice);
  
    setProductData((prevData) => ({
      ...prevData,
      discount: newDiscount,
          }));
  },[isProductData.mrp, isProductData.saleprice, isProductData.taxpercentage, isProductData.baseprice]);
  


const handleRadioChange = (inputType) => {
  setSelectedInput(inputType);
  if (inputType === "saleprice") {

    const newSalePrice = new Big(parseFloat(isProductData.saleprice) || 0);
    const taxRate = new Big(parseFloat(isProductData.taxpercentage) || 0);

    Big.RM = 0;
    const newBasePrice = newSalePrice
    ? (newSalePrice.times(100)).div((new Big(100).plus(taxRate))).round(2).toString()
    : "0.00";
    Big.RM = 3;
    const newTaxAmount = newSalePrice && newBasePrice
? (newSalePrice.minus(newBasePrice)).round(2).toString()  
: "0.00";

  
  
    setProductData((prevData) => {
      return {
        ...prevData,
        saleprice: newSalePrice, 
        baseprice: newBasePrice, 
        taxamount: newTaxAmount,
      };
    });
  }
  
  else if(inputType === "baseprice"){
 
  const newBasePrice = new Big(parseFloat(isProductData.baseprice) || 0);
  const taxRate = new Big(parseFloat(isProductData.taxpercentage) || 0);
  Big.RM = 3; 
  const calculatedTaxAmount = newBasePrice.times(taxRate).div(new Big(100)).round(2);

  Big.RM = 0;  
  const calculatedSalePrice = newBasePrice.plus(calculatedTaxAmount).round(2).toString(); 
    console.log("Calculated Tax Amount:", calculatedTaxAmount);
  
  setProductData((prevData) => {
    return {
      ...prevData,
      baseprice: newBasePrice,
      saleprice: calculatedSalePrice,
      taxamount: calculatedTaxAmount,
    };
  });
    
  }
};
const handleTaxPercentageChange = (e) => {
  const newTaxPercentage = parseFloat(e.target.value);

  setProductData((prevData) => {
    let updatedSalePrice, updatedBasePrice, updatedTaxAmount;

    if (selectedInput === "saleprice") {
      updatedSalePrice = parseFloat(prevData.saleprice) || 0;
  
      updatedBasePrice = (
        Math.floor((updatedSalePrice / (1 + newTaxPercentage / 100)) * 100) / 100
      )  

 updatedTaxAmount = updatedSalePrice && updatedBasePrice
      ? (updatedSalePrice - updatedBasePrice).toFixed(2)  
      : "0.00";
     
    }
 
    else if (selectedInput === "baseprice") {
      updatedBasePrice = parseFloat(prevData.baseprice)|| 0;
    
   updatedTaxAmount =  Math.ceil(((updatedBasePrice/100)*newTaxPercentage)*100)/100
       updatedSalePrice = updatedBasePrice+updatedTaxAmount;
    }
    
    let updatedMrp = prevData.mrp;
    if (parseFloat(updatedSalePrice) > parseFloat(prevData.mrp)) {
      updatedMrp = updatedSalePrice;
    }

    return {
      ...prevData,
      taxpercentage: newTaxPercentage,
      saleprice: updatedSalePrice,
      baseprice: updatedBasePrice,
      taxamount: updatedTaxAmount,
      mrp: updatedMrp,
    };
  });
};




const handleSellingPriceChange = (e) => {
  let inputSalePrice = e.target.value;

  // Remove any non-numeric characters except for the decimal point
  inputSalePrice = inputSalePrice.replace(/[^0-9.]/g, "");

  // Allow only one decimal point
  if ((inputSalePrice.match(/\./g) || []).length > 1) {
    inputSalePrice = inputSalePrice.slice(0, inputSalePrice.lastIndexOf('.'));
  }

  // If there is a decimal point, limit it to two digits
  const parts = inputSalePrice.split(".");
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2);
    inputSalePrice = parts.join(".");
  }

  // Ensure the inputSalePrice is at least a number (or 0 if invalid)
  const salePriceNumber = parseFloat(inputSalePrice) || 0;

  // Update the displayed sale price in state
  setProductData((prevData) => ({
    ...prevData,
    saleprice: inputSalePrice, // Display the value as the user types
  }));

  // If the input is valid (a number), proceed with calculations
  if (!isNaN(salePriceNumber)) {
    const newSalePrice = new Big(salePriceNumber);

    // Set the tax rate
    const taxRate = new Big(parseFloat(isProductData.taxpercentage) || 0);

    // Calculate base price and tax amount with specified rounding modes
    Big.RM = 0; // Round down
    const newBasePrice = newSalePrice.times(100).div(new Big(100).plus(taxRate)).round(2).toString();

    Big.RM = 3; // Round up
    const newTaxAmount = newSalePrice.minus(newBasePrice).round(2).toString();

    // Update the state with calculated base price and tax amount
    setProductData((prevData) => ({
      ...prevData,
      baseprice: newBasePrice,
      taxamount: newTaxAmount,
    }));
  }
};
const handleBasePriceChange = (e) => {
  let value = e.target.value;

  
  let InputBasePrice = value.replace(/[^0-9.]/g, "");

  
  if ((InputBasePrice.match(/\./g) || []).length > 1) {
   
    InputBasePrice = InputBasePrice.slice(0, InputBasePrice.lastIndexOf('.'));
  }

  
  let newBasePrice = new Big(parseFloat(InputBasePrice) || 0);

  
  if (newBasePrice.toString() === "0" && e.nativeEvent.inputType === "deleteContentBackward") {
    newBasePrice = new Big(0);
    isProductData.saleprice = "";
    isProductData.taxamount = "";
  }

 
  const parts = InputBasePrice.split(".");
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2); 
    InputBasePrice = parts.join(".");
  }

  setProductData((prevData) => {
    if (newBasePrice.toString() === "0") {
      return {
        ...prevData,
        baseprice: newBasePrice.toString(),
      };
    }

    const taxRate = new Big(parseFloat(isProductData.taxpercentage) || 0);

    Big.RM = 3; 
    const calculatedTaxAmount = newBasePrice.times(taxRate).div(new Big(100)).round(2);

    Big.RM = 0; 
    const SalePrice = newBasePrice.plus(calculatedTaxAmount).round(2);

    console.log("BasePrice:", newBasePrice.toString());
    console.log("Tax Amount:", calculatedTaxAmount.toString());
    console.log("Sale Price:", SalePrice.toString());

    const updatedData = {
      ...prevData,
      baseprice: InputBasePrice, 
      saleprice: SalePrice.toString(),
      taxamount: calculatedTaxAmount.toString(),
    };

    
    if (parseFloat(SalePrice) > parseFloat(prevData.mrp)) {
      updatedData.mrp = SalePrice.toString();
    }

    return updatedData;
  });
};






  
  const calculateDiscount = (mrp, saleprice) => {
    const mrpValue = parseFloat(mrp) || 0;
    const salepriceValue = parseFloat(saleprice) || 0;

    const discount = mrpValue - salepriceValue;

    if (discount < 0) {
      return "0";
    } else if (discount === 0) {
      return "0.00";
    } else {
      return discount.toFixed(2);
    }
  };
  const handleKeyDown = (e, fieldRef, fieldName) => {
    setError("");
    if (e.key === "Enter") {
      e.preventDefault();

      const fields = [
        { name: "code", ref: productCodeRef, required: true },
        { name: "hsnsac", ref: productHsnsacRef, required: false },
        { name: "title", ref: productTitleRef, required: true },
        { name: "mrp", ref: productMrpRef, required: false },
        { name: "saleprice", ref: productsalepriceRef, required: true },
        {
          name: "taxpercentage",
          ref: producttaxpercentageeRef,
          required: true,
        },
        { name: "baseprice", ref: productbasepriceRef, required: false },
      ];

      const currentIndex = fields.findIndex(
        (field) => field.name === fieldName
      );

      if (fieldName === "baseprice") {
        handleAdd();
        return;
      }
    
      if (
        fields[currentIndex].required &&
        isProductData[fieldName]?.trim() === ""
      ) {
        fieldRef?.current?.focus();
        return;
      }

      const nextField = fields[currentIndex + 1];
      if (nextField) {
        nextField.ref?.current?.focus();
      }
    }
  };

  useEffect(() => {
    productCodeRef.current && productCodeRef.current.focus();
    fetchRecentProduct();
  }, []);
  return (
    <div>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row">
            <div class="col-lg-5">
              <h6 class="card-title ">Add Product</h6>
              <div class="card mt-3">
                <div class="card-body">
                  <form class="row g-3">
                    <div class="col-lg-8">
                      <label htmlFor="code" class="form-label">
                        Barcode / SKU / Code
                      </label>
                      <span class="text-danger">*</span>

                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          id="bsValidation1"
                          name="code"
                          autoComplete="off"
                          value={isProductData.code}
                          onChange={handleChange}
                          ref={productCodeRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, productCodeRef, "code")
                          }
                        />
                        {barcodeloading ? (
                          <ButtonWithProgress
                            label="Generating...."
                            iconClass="bx bx-barcode"
                            buttonClass="btn btn-primary text-right"
                          />
                        ) : (
                          <AddButton
                            label="Generate"
                            iconClass="bx bx-barcode"
                            buttonClass="btn btn-primary text-right"
                            onClick={handleGenerateBarcode}
                          />
                        )}
                      </div>
                      {errors.code && (
                        <div className="error-message invalid-feedback">
                          {errors.code}
                        </div>
                      )}
                      <div className="valid-feedback"></div>
                    </div>
                    <div class="col-lg-4">
                      <label htmlFor="hsnsac" className="form-label">
                        HSN/SAC
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="bsValidation1"
                        name="hsnsac"
                        autoComplete="off"
                        ref={productHsnsacRef}
                        value={isProductData.hsnsac}
                        onChange={handleChange}
                        onKeyDown={(e) =>
                          handleKeyDown(e, productHsnsacRef, "hsnsac")
                        }
                      />
                      <div class="valid-feedback"></div>
                    </div>
                    <div class="col-lg-12">
                      <label htmlFor="title" class="form-label">
                        Title <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        autoComplete="off"
                        value={isProductData.title}
                        onChange={handleChange}
                        ref={productTitleRef}
                        onKeyDown={(e) =>
                          handleKeyDown(e, productTitleRef, "title")
                        }
                      />
                      {errors.title && (
                        <div className="error-message invalid-feedback">
                          {errors.title}
                        </div>
                      )}
                    </div>
                    <div class="col-lg-4">
                      <label htmlFor="mrp" class="form-label">
                        MRP
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="mrp"
                        autoComplete="off"
                        ref={productMrpRef}
                        onKeyDown={(e) =>
                          handleKeyDown(e, productMrpRef, "mrp")
                        }
                        value={isProductData.mrp}
                        onChange={handleMrpChange}
                        onBlur={handleBlur}
                      />

                      {errors.mrp && (
                        <div className="error-message invalid-feedback">
                          {errors.mrp}
                        </div>
                      )}
                    </div>
                    <div class="col-lg-4">
                      <label htmlFor="saleprice" class="form-label">
                        Sale Price <span class="text-danger">*</span>
                      </label>

                      <div className="input-container position-relative">
                        <input
                          type="text"
                          name="saleprice"
                          autoComplete="off"
                          value={isProductData.saleprice}
                          onClick={() => handleRadioChange("saleprice")}
                          onChange={handleSellingPriceChange}
                          ref={productsalepriceRef}
                          onKeyDown={(e) => handleKeyDown(e, productsalepriceRef, 'saleprice')} 
                          className="form-control"
                          style={{ paddingLeft: "30px" }}
                          onBlur={handleBlur}
                        />
                        <input
                          type="radio"
                          name="selectInput"
                          checked={selectedInput === "saleprice"}
                          onChange={() => handleRadioChange("saleprice")}
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        />
                      </div>

                      {errors.saleprice && (
                        <div className="error-message invalid-feedback">
                          {errors.saleprice}
                        </div>
                      )}
                    </div>
                    <div class="col-lg-4">
                      <label htmlFor="FisrtName" class="form-label">
                        Tax(%) <span class="text-danger">*</span>
                      </label>
                      <select
                        name="taxpercentage"
                        className="form-control"
                        value={isProductData.taxpercentage}
                        ref={producttaxpercentageeRef}
                        onChange={handleTaxPercentageChange}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            producttaxpercentageeRef,
                            "taxpercentage"
                          )
                        }
                      >
                        <option value="0">0</option>
                        <option value="5">5</option>
                        <option value="12">12</option>
                        <option value="18">18</option>
                        <option value="24">24</option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label htmlFor="discount" class="form-label">
                        Discount
                      </label>
                      <input
                        readOnly=""
                        type="text"
                        name="discount"
                        autoComplete="off"
                        value={isProductData.discount}
                        onChange={handleChange}
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="baseprice" className="form-label">
                        Base Price
                      </label>

                      <div className="input-container position-relative">
                        <input
                          type="text"
                          name="baseprice"
                          autoComplete="off" 
                          ref={productbasepriceRef}
                          value={isProductData.baseprice}
                          onClick={() => handleRadioChange("baseprice")}
                          onChange={handleBasePriceChange}
                          onKeyDown={(e) => handleKeyDown(e, productbasepriceRef, 'baseprice')} 
                          onBlur={handleBlur}
                          
                          className="form-control"
                          style={{ paddingLeft: "30px" }}
                        />
                        <input
                          type="radio"
                          name="selectInput"
                          checked={selectedInput === "baseprice"}
                          onChange={() => handleRadioChange("baseprice")}
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        />  
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="taxamount" className="form-label">
                        Tax Amount
                      </label>

                      <input
                        readOnly=""
                        type="text"
                        name="taxamount"
                        autoComplete="off"
                        value={
                          isProductData.taxpercentage === "0"
                            ? "0.00"
                            : isProductData.taxamount
                        }
                        class="form-control"
                        disabled
                      />
                    </div>
                  </form>
                  <div className="mt-4">
                    <CancelButton
                      buttonText="Cancel"
                      buttonIcon="bx bx-x"
                      onClick={handleClose}
                      additionalClasses="btn-outline-secondary text-left"
                    />
                    {buttonloading ? (
                      <ButtonWithProgress
                        label="Adding...."
                        iconClass="ng-ng-add-icon"
                        buttonClass="btn btn-primary text-right"
                        onClick={handleAdd}
                      />
                    ) : (
                      <AddButton
                        label="Add"
                        iconClass="ng-ng-add-icon"
                        ref={AddButtonRef}
                        buttonClass="btn btn-primary text-right"
                        onClick={handleAdd}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <h6 class="card-title ">Recently Added Products</h6>
              <div class="card radius-10 mb-0 mt-3">
                <div class="card-body px-0">
                  <AddProductTable isData={isData} isloading={isloading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddProducts;
