import React from 'react'

function StokesTable({products}) {
  return (
    <div>
         <div className="table-responsive mt-3">
      <table className="table align-middle mb-0">
        <thead className="table-light text-center">
          <tr>
            <th>S.No</th>
            <th>Barcode</th>
            <th>Description</th>
            <th>MRP</th>
            <th>Sale Price</th>
            <th>Tax</th>
            <th>Stock Qty</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">{product.barcode}</td>
              <td className="text-center">
                <span className="text-nowrap text-heading fw-medium">{product.description}</span>
              </td>
              <td className="text-center">₹{product.mrp}</td>
              <td className="text-center">
                <span className="text-heading fw-medium text-truncate">₹{product.salePrice}</span>
              </td>
              <td className="text-center">
                <span className="badge bg-light-purple">{product.tax} %</span>
              </td>
              <td className="text-center">{product.stockQty}</td>
              <td className="text-center">
                <button type="button" data-bs-toggle="modal" data-bs-target="#StockModal" className="btn shadow-none btn-label-success btn-sm px-2">
                  <i className="bx bx-chevron-up-circle"></i> Stock
                </button>
                <button type="button" className="btn btn-label-primary shadow-none btn-sm radius-5 px-2 ms-2">
                  <i className="bx bx-show"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default StokesTable
