// blue theme add with plus
import React from 'react';

function CustomButtonAdd({ text, icon,  className , onClick }) {
  return (
    <button
      type="button"
      className={`btn ${className}`}
     
      onClick={onClick}
    >
      {icon && <i className={`bx ${icon}`} ></i>} {text}
    </button>
  );
}

export default CustomButtonAdd;
