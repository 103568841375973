import React from 'react';

function SearchBox({ placeholder, buttonIcon, inputValue, onInputChange, onSearch }) {
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        fdprocessedid="vlw39b"
        placeholder={placeholder}
        value={inputValue}
        onChange={onInputChange} 
      />
      <button 
        className="input-group-text" 
        fdprocessedid="1vtzoc"
        onClick={onSearch}
        style={{ backgroundColor: "#ededff" }} 
      >
        <i className={buttonIcon}></i>
      </button>
    </div>
  );
}

export default SearchBox;
