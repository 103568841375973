import React, { useState } from "react";
import CustomButton from "../reusable/salesButton/CustomButton";
import DatePickerButton from "../reusable/salesButton/DatePickerButton";
import SearchBox from "../reusable/salesButton/SearchBox";
import BarcodeScanner from "../reusable/salesButton/BarcodeScanner";
import CancelButton from "../reusable/CancelButton";
import "../subMenu/Sales.css";


import SalesTable from "../reusable/salesButton/SalesTable";
const tableData = [
  {
    id: 1,
    name: "Champno no no no Shuttle Tube",
    price: "₹1200",
    amount: "₹1200",
    total: "₹1200",
    quantity: 1,
    totalAmount: "₹1200.00",
  },
  {
    id: 2,
    name: "Lining Champ Shuttle Tube",
    price: "₹120220",
    amount: "₹120220",
    total: "₹1200",
    quantity: 1,
    totalAmount: "₹120220.00",
  },
  {
    id: 3,
    name: "Apple Watch Series 7 Starlight Aluminum Case with Starlight Sport Band.",
    price: "₹120220",
    amount: "₹120220",
    total: "₹1200",
    quantity: 1,
    totalAmount: "₹120220.00",
  },
  {
    id: 4,
    name: "Revito Premium Washing Powder",
    price: "₹120220",
    amount: "₹120220",
    total: "₹1200",
    quantity: 1,
    totalAmount: "₹120220.00",
  },
  {
    id: 5,
    name: "Bizz Powder Plus Detergent Cake",
    price: "₹120220",
    amount: "₹120220",
    total: "₹1200",
    quantity: 1,
    totalAmount: "₹120220.00",
  },
];
function Sales() {
  const [inputValue, setInputValue] = useState("09 Jan 2024");
  const [searchValue, setSearchValue] = useState("");
  const [searchValueDes, setSearchValueDes] = useState("");
  const [barcode, setBarcode] = useState("");
  const handleSearch = () => {
    console.log("Search initiated for:", searchValue);
  };
  const handleScan = () => {
    console.log("Barcode scanned:", barcode);
  };
  const handleCancel = () => {
    console.log("Cancel button clicked");
  };
  const handleEdit = (id) => {
    console.log("Edit item with id:", id);
  
  };

  const handleDelete = (id) => {
    console.log("Delete item with id:", id);
  
  };

  return (
    <div className="wrapper">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row py-0">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header px-3 py-3 d-flex flex-wrap justify-content-between align-items-center gap-3">
                  <div className="mb-0">
                    <DatePickerButton
                      icon="bx bx-calendar"
                      inputType="text"
                      inputValue={inputValue}
                      className="createdon"
                      onInputChange={(e) => setInputValue(e.target.value)}
                      inputPlaceholder="Enter Date"
                    />
                  </div>
                  <div className="d-flex justify-content-md-end align-items-center gap-2 flex-wrap">
                    <CustomButton
                      text="Recent Sales"
                      icon="bx bx-refresh"
                      className="btn-light-dark"
                      onClick={() => console.log("Recent Sales clicked")}
                    />
                    <CustomButton
                      text="Add Product"
                      icon="bx bx-plus"
                      className="btn-light-primary"
                      onClick={() => console.log("Add Product clicked")}
                    />
                    <CustomButton
                      text="Add Service"
                      icon="bx bx-plus"
                      className="btn-light-info"
                      onClick={() => console.log("Add Service clicked")}
                    />
                  </div>
                </div>
                <div className="card-body mt-3 py-0">
                  <div className="row g-2">
                    <div className="col-sm-8">
                      <SearchBox
                        placeholder="Search product by description..."
                        buttonIcon="bx bx-search"
                        inputValue={searchValue}
                        onInputChange={(e) => setSearchValue(e.target.value)}
                        onSearch={handleSearch}
                      />
                    </div>

                    <div className="col-sm-4">
                      <BarcodeScanner
                        placeholder="Scan Barcode..."
                        buttonIcon="bx bx-barcode"
                        inputValue={barcode}
                        onInputChange={(e) => setBarcode(e.target.value)}
                        onScan={handleScan}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-body px-0">
					<SalesTable   tableData={tableData} 
        handleEdit={handleEdit} 
        handleDelete={handleDelete} />
                
                  <div className="row bg-light border-0 mt-2 text-center align-items-center m-0">
                    <div className="col p-2">
                      <p className="mb-0">Total MRP</p>
                      <h6 className="mb-0 fs-20">1800.00</h6>
                    </div>
                    <div className="col p-2 fs-20">
                      <i className="bx bx-minus text-muted"></i>
                    </div>
                    <div className="col p-2">
                      <p className="mb-0">Total Discount</p>
                      <h6 className="mb-0 fs-20">292.00</h6>
                    </div>
                    <div className="col p-2 fs-20">
                      <i className="lni lni-line-double text-muted"></i>
                    </div>
                    <div className="col bg-dark-blue p-2">
                      <p className="mb-0 bg-dark-text">Grand Total</p>
                      <h6 className="mb-0 bg-dark-text fs-20">1508.00</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <form method="POST" className="mb-2">
                    <div className="ms-auto position-relative">
                      <SearchBox
                        placeholder="Search customer by name, phone etc..."
                        buttonIcon="bx bx-search"
                        inputValue={searchValueDes}
                        onInputChange={(e) => setSearchValueDes(e.target.value)}
                        onSearch={handleSearch}
                      />
                    </div>
                  </form>
                  <div className="bg-light-white px-3 py-2 radius-10">
                    <div className="d-flex justify-content-between">
                      <h6 className="mb-2">Customer info</h6>
                      <h6 className="mb-2">
                        <a
                          href=" #"
                          data-bs-toggle="modal"
                          data-bs-target="#editUser"
                        >
                          Edit
                        </a>
                      </h6>
                    </div>
                    <p className=" mb-1">Name: Shamus889</p>
                    <p className=" mb-0">Phone: +1 (609) 972-22-22</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="rounded mb-3">
                    <div className="bg-light  rounded p-2 text-center mb-3">
                      <h6 className="mb-0 fs-18 text-dark">
                        Total Payable : <span> 0.00 </span>
                      </h6>
                    </div>
                    <div className="flex-grow-1 row justify-content-between align-items-center g-2">
                      <div className="col-6">
                        <h6 className="mb-0">Cash</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-6">
                        <h6 className="mb-0">Card</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>

                      <div className="col-6">
                        <h6 className="mb-0">Online Pay (UPI)</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-6">
                        <h6 className="mb-0 text-danger">Balance</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-6">
                        <h6 className="mb-0 text-dark">Total Paid</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-6">
                        <h6 className="mb-0 text-danger">Pay Later (Credit)</h6>
                      </div>
                      <div className="col-6 text-end">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="d-block">
                    <CancelButton
                      buttonText="Cancel"
                      buttonIcon="bx bx-x"
                      onClick={handleCancel}
                      additionalClasses="btn-outline-secondary text-left"
                    />

                    <button
                      type="button"
                      className="btn btn-primary text-right w-48"
                    >
                      <i className="bx bx-check"></i> Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sales;
