import React from 'react';

function BarcodeScanner({ placeholder, buttonIcon, inputValue, onInputChange, onScan }) {
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={inputValue}
        onChange={onInputChange}
      />
      <button className="input-group-text" onClick={onScan}>
        <i className={buttonIcon}></i>
      </button>
    </div>
  );
}

export default BarcodeScanner;
