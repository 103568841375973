import React, { useEffect, useState } from "react";
import EditButton from "../EditIcon";
import DeleteButton from "../DeleteIcon";
import useErrorHandle from "../../error/ErrorHandling";
import { getAccessStoreToken, getAccessToken, getStoreId } from "../../utils/CookieUtiles";
import { Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EditProductDialog from "../../menuList/products/EditProductDialog";
import SearchBox from "../salesButton/SearchBox";
import DeleteDialog from "../buttons/Deletedialog";
import CustomButtonAdd from "../CusstomButtonAdd";
import { useNavigate } from "react-router-dom";
import AddNegoButton from "../AddNegoButton";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ListProductTable() {
  const [isData, setData] = useState([]);
  const base_apiUrl = process.env.REACT_APP_BASE_URL;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [buttonloading, setButtonLoading] = useState(false);
  const handleError = useErrorHandle();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const authToken = getAccessStoreToken();
  const navigate = useNavigate();
  const storeid=getStoreId();
  const fetchListProduct = async () => {
    setLoading(true);
    const url = `${base_apiUrl}/list-product`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        storeid:storeid
        })
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setData(data.results);
      console.log("data.results", data.results);
    } catch (error) {
      console.error("Error fetching Added list product:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = async (updatedProduct) => {
    setButtonLoading(true);

    const url = `${base_apiUrl}/update-product`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        storeid:storeid,
        productid: updatedProduct.productid,
        code: updatedProduct.code,
        mrp: updatedProduct.mrp,
        saleprice: updatedProduct.saleprice,
        title: updatedProduct.title,
        hsnsac: updatedProduct.hsnsac,
        taxpercentage: updatedProduct.taxpercentage,
        discount: updatedProduct.discount,
        baseprice: updatedProduct.baseprice,
        taxamount: updatedProduct.taxamount,
      }),
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response:", errorData);
        await handleError(response);
      }

      const data = await response.json();

      setData((prevProducts) => {
        return prevProducts.map((product) =>
          product._id === data.results._id ? data.results : product
        );
      });

      setSnackbarSeverity("success");
      setSnackbarMessage("Product updated successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false);
    } catch (error) {
      console.error("Error updating product:", error);
      setButtonLoading(false);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error updating product.");
      setSnackbarOpen(true);
    }
  };
  useEffect(() => {
    fetchListProduct();
  }, []);
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "":
        return "bg-label-success1";
      case "complete":
        return "bg-label-info";
      case "active":
        return "bg-label-success";
      default:
        return "bg-label-default";
    }
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
  };

  const handleEditModel = (product) => {
    console.log("edit", product._id);
    setSelectedProduct(product);
    setIsDialogOpen(true);
  };
  const UpdateProduct = (updatedProductData) => {
    console.log("updates product", updatedProductData);
    console.log("updates", updatedProductData.productid);
    handleEdit(updatedProductData);
    handleCloseDialog();
  };

  const filteredData = isData.filter((product) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (product.code && product.code.toLowerCase().includes(searchLower)) ||
      (product.title && product.title.toLowerCase().includes(searchLower)) ||
      (product.mrp && product.mrp.toString().includes(searchTerm)) ||
      (product.saleprice && product.saleprice.toString().includes(searchTerm))
    );
  });
  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleDelete = async (CustomerId) => {
    console.log("deleteid",CustomerId)
    const url = `${base_apiUrl}/delete-product`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ storeid:storeid, productid : CustomerId }),
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
        return;
      }
  
 
      setData((prevCustomerData) =>
        prevCustomerData.filter((customer) => customer.CustomerId !== CustomerId)
      );
      fetchListProduct();
      setSnackbarSeverity("success");
      setSnackbarMessage("Deleted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting product:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error deleting product.");
      setSnackbarOpen(true);
    }
  };


  const confirmDelete = () => {
    handleDelete(selectedProductData._id);
    setIsDeleteDialogOpen(false);
  };
  const openDeleteDialog = (product) => {
    setSelectedProductData(product);
    setIsDeleteDialogOpen(true);
  };

  const handleAddProduct = () => {
    navigate("/products");
  };

  return (
    <>
             <div className="row">
        <div className="col">
          <div className="card radius-10 mb-0 ">
            <div className="card-header justify-content-between align-items-center p-0">
              <div className="card-body px-3 py-3 ">
                <div className="d-flex align-items-center justify-content-between gap-3">
               
                  <div className="col-6">
                    <div className="position-relative">
                    <SearchBox
              placeholder="Search products by name, phone etc..."
              buttonIcon="bx bx-search"
              inputValue={searchTerm}
              onInputChange={(e) => setSearchTerm(e.target.value)}
              onSearch={() => console.log("Search button clicked!")}
            />
                    </div>
                  </div>
                  
                 
                  <div>
                  <AddNegoButton
                text="Add Product"
                icon="ng-ng-product-add-icon"
                onClick={handleAddProduct}
                className="btn-primary mt-2 mt-lg-0"
              />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
        <table className="table align-middle mb-0">
          <thead className="table-light text-center">
            <tr>
              <th>S.No</th>
              <th>Barcode</th>
              <th>Title</th>
              <th>MRP</th>
              <th>Sale Price</th>
              <th>Tax</th>
              <th>Stock Qty</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isloading ? (
              [...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Skeleton variant="text" width={200} />
                  </td>

                  <td className="text-center">
                    <Skeleton variant="center" width={300} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                  <td className="text-center">
                    <Skeleton variant="text" width={100} />
                  </td>
                </tr>
              ))
            ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={item.id || index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">
                    {item.code ? (
                      <span className="badge  bg-label-blue">{item.code}</span>
                    ) : null}
                  </td>
                  <td className="text-center">
                    <span className="text-nowrap text-heading fw-medium" style={{ textTransform: 'capitalize' }}>
                      {item.title}
                    </span>
                  </td>
                  <td className="text-center">₹{item.mrp || "0.0"}</td>
                  <td className="text-center">
                    <span className="text-heading fw-medium text-truncate">
                      ₹{item.saleprice || "0.0"}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge bg-light-purple">
                      {item.taxpercentage ? `${item.taxpercentage}%` : "0%"}
                    </span>
                  </td>
                  <td className="text-center">{item.stockqty}</td>
                  <td className="text-center">
                    <span
                      className={`badge rounded-pill ${getColor(
                        item.status ? item.status.toLowerCase() : ""
                      )}`}
                    >
                      {item.status
                        ? item.status.charAt(0).toUpperCase() +
                          item.status.slice(1).toLowerCase()
                        : "No status"}
                    </span>
                  </td>

                  <td className="text-center">
                    <div className="order-actions">
                      <EditButton onEdit={() => handleEditModel(item)} />
                      <DeleteButton onDelete={() => openDeleteDialog(item)} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isDialogOpen && (
          <EditProductDialog
            productData={selectedProduct}
            onClose={handleCloseDialog}
            updateProduct={UpdateProduct}
            buttonloading={buttonloading}
          />
        )}






{isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name=""
        />
      )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>

                 
                </div>
              </div>
            </div>
          </div>
     
    </>
  );
}

export default ListProductTable;
