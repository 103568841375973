import React, { useState } from 'react'
import SearchBox from '../../reusable/salesButton/SearchBox'
import StokesTable from '../../reusable/tables/StokesTable';
const data=[
    {
      "sNo": 1,
      "barcode": "75257",
      "description": "SAMSUNG Galaxy S22 Ultra",
      "mrp": "899",
      "salePrice": "899",
      "tax": "18 ",
      "stockQty": 447
    },
    {
      "sNo": 2,
      "barcode": "92885",
      "description": "MacBook Pro 16",
      "mrp": "2648.95",
      "salePrice": "2448",
      "tax": "18 ",
      "stockQty": 965
    },
    {
      "sNo": 3,
      "barcode": "63474",
      "description": "Canon EOS Rebel T7",
      "mrp": "399",
      "salePrice": "390",
      "tax": "5 ",
      "stockQty": 810
    },
    {
      "sNo": 4,
      "barcode": "46658",
      "description": "Apple Watch Series 7",
      "mrp": "799",
      "salePrice": "750",
      "tax": "12 ",
      "stockQty": 519
    },
    {
      "sNo": 5,
      "barcode": "5829",
      "description": "Amazon Fire TV",
      "mrp": "263.49",
      "salePrice": "250",
      "tax": "0 ",
      "stockQty": 587
    },
    {
      "sNo": 6,
      "barcode": "72836",
      "description": "Echo Dot (4th Gen)",
      "mrp": "25.50",
      "salePrice": "25",
      "tax": "20 ",
      "stockQty": 827
    },
    {
      "sNo": 7,
      "barcode": "41867",
      "description": "BANGE Anti Theft Backpack",
      "mrp": "79.99",
      "salePrice": "75",
      "tax": "8 ",
      "stockQty": 519
    }
  ]
  
function ManageStokes() {
    const [searchValue, setSearchValue] = useState("");
    const handleSearch = () => {
        console.log("Search initiated for:", searchValue);
      };
  return (
    <div>
     <div class="page-wrapper">
			<div class="page-content">
                <div class="row">
					<div class="col">
                        <h6 class="card-title py-2">All Products</h6>
						<div class="card radius-10 mb-0 mt-3">
							<div class="card-body px-0">
                                <div class="row px-3">
                                    <div class="col-lg-5 me-auto">
                                        <div class="ms-auto position-relative">
                                        <SearchBox
                         placeholder="Search products by name, phone etc..."
                        buttonIcon="bx bx-search"
                        inputValue={searchValue}
                        onInputChange={(e) => setSearchValue(e.target.value)}
                        onSearch={handleSearch}
                      />
                                            
                                        </div>
                                    </div>
                                </div>


<StokesTable products={data}/>
                            
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
    </div>
  )
}

export default ManageStokes
